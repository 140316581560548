<template>
  <v-card class="mx-auto mb-12" :max-width="$vuetify.breakpoint.mdAndUp ? '1000' : '500'" :loading="loading">
    <v-card-text>
      <v-row>
        <v-col cols="8">
          <div class="title text--primary">Gym areas</div>
          Define individual accessible areas and the allowed number of clients. Clients can select their area upon
          booking, eg. 'Bouldering' and 'Lead'.
        </v-col>
      </v-row>

      <v-divider class="my-3" />

      <v-row class="mt-n3">
        <v-col cols="12" md="6">
          <div class="subtitle-2 text--primary">Existing areas</div>
        </v-col>
        <v-col cols="12" md="6" class="mt-n3">
          <tl-reservation-admin-settings-area v-for="area in areas" :key="area.id" :area="area" @refresh="fetchAreas" />
        </v-col>
      </v-row>

      <v-divider class="my-3" />

      <v-row class="mt-n3">
        <v-col cols="12" md="6">
          <div class="subtitle-2 text--primary">Add a new area</div>
        </v-col>
        <v-col cols="12" md="6" class="mt-n3">
          <tl-reservation-admin-settings-area @refresh="fetchAreas" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import axios from '@/services/axios'
import errorService from '@/services/error-service'
import tlReservationAdminSettingsArea from './tl-reservation-admin-settings-area'

export default {
  components: {
    tlReservationAdminSettingsArea,
  },
  data: () => ({
    loading: false,
    areas: [],
  }),
  computed: {
    ...mapState(['gym']),
  },
  created() {
    this.fetchAreas()
  },
  methods: {
    fetchAreas() {
      this.loading = true
      axios
        .get(`/gyms/${this.gym.id}/reservation_areas`)
        .then(({ data }) => (this.areas = data))
        .catch(errorService.toast)
        .finally(() => (this.loading = false))
    },
  },
}
</script>
