<template>
  <v-list-item>
    <v-list-item-content class="text--lighten-1" :class="`${durationColor}--text`">
      <v-list-item-title style="user-select: text">
        {{ reservation.first_name }} {{ reservation.last_name }}
      </v-list-item-title>
      <v-list-item-subtitle class="d-flex align-center text--lighten-1" :class="`${durationColor}--text`">
        <v-icon size="14" :color="durationColor" class="mr-1">tl-alarm</v-icon>
        {{ durationText }} remaining
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action>
      <v-btn text :loading="updating" @click="checkOut">
        Check out
      </v-btn>
    </v-list-item-action>
    <v-list-item-action class="my-0 ml-0">
      <v-menu left offset-y min-width="250">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on"><v-icon>tl-more-vert</v-icon></v-btn>
        </template>
        <v-list>
          <v-list-item v-if="reservation.in_at" @click="undoCheckIn()">
            <v-list-item-icon><v-icon>tl-restore</v-icon></v-list-item-icon>
            <v-list-item-title>Undo check-{{ reservation.out_at ? 'out' : 'in' }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="reservation.in_at && !reservation.out_at"
            :disabled="!reservation.email"
            @click="sendCheckoutReminder()"
          >
            <v-list-item-icon><v-icon>tl-reminder</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Send checkout reminder</v-list-item-title>
              <v-list-item-subtitle v-if="!reservation.email">No email available</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import axios from '@/services/axios'
import errorService from '@/services/error-service'
import tlReservationsUpdateIntervalMixin from './tl-reservations-update-interval.mixin'

export default {
  mixins: [tlReservationsUpdateIntervalMixin],
  props: {
    reservation: { type: Object, default: () => ({}) },
  },
  data: () => ({
    updating: false,
    duration: null,
  }),
  computed: {
    durationText() {
      let minus = this.duration <= -60000 ? '-' : ''
      let hourMinutes = this.moment.utc(Math.abs(this.duration)).format('H:mm')
      return `${minus}${hourMinutes}`
    },
    durationColor() {
      if (this.duration <= 0) return 'error'
      if (this.duration < 600000) return 'warning' // Last ten minutes
      return ''
    },
  },
  methods: {
    updateIntervalFunction() {
      let timeThatDay = this.updateDate(this.moment(), this.moment(this.reservation.slot_end_at).format('YYYY-MM-DD'))
      let diff = this.moment(this.reservation.slot_end_at).diff(timeThatDay)
      this.duration = this.moment.duration(diff).as('milliseconds')
    },
    updateDate(dateTime, date) {
      date = date || this.moment(dateTime).format('YYYY-MM-DD')
      let time = this.moment(dateTime).format('HH:mm')
      return this.moment(date + 'T' + time).toISOString()
    },
    undoCheckIn() {
      this.$store.dispatch('reservations/updateReservation', {
        reservation: this.reservation,
        update: { in_at: null },
      })
    },
    checkOut() {
      this.updating = true
      this.$store
        .dispatch('reservations/updateReservation', {
          reservation: this.reservation,
          update: { out_at: this.moment().toISOString() },
        })
        .finally(() => (this.updating = false))
    },
    sendCheckoutReminder() {
      if (!this.reservation.email) return
      axios
        .put(`/gyms/${this.$store.state.gym.id}/reservations/${this.reservation.id}/send_checkout_reminder`)
        .then(() => this.$store.dispatch('toast/success', 'A reminder email has been send'))
        .catch(errorService.toast)
    },
  },
}
</script>
