<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="textfield"
        v-model="dateTextField"
        :label="label"
        :prepend-icon="typeable ? prependIcon : null"
        :style="{ 'max-width': maxWidth }"
        :single-line="singleLine"
        :solo="solo"
        :rounded="rounded"
        :hide-details="hideDetails"
        :dense="dense"
        :rules="rules"
        :clearable="clearable"
        :readonly="!typeable"
        v-bind="typeable ? null : attrs"
        v-on="typeable ? null : on"
        @blur="onBlurTextfield"
        @click:prepend="menu = !menu"
      />
    </template>
    <v-date-picker
      v-model="date"
      :locale="$i18n.locale"
      :first-day-of-week="moment.localeData()._week.dow"
      :landscape="$vuetify.breakpoint.smAndUp"
      color="primary"
      @input="menu = false"
    />
  </v-menu>
</template>

<script>
import { date as ruleDate, required as ruleRequired } from '@/services/input-rules'

export default {
  props: {
    value: { type: String, default: null },
    format: { type: String, default: 'l' },
    label: { type: String, default: 'Date' },
    typeable: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
    maxWidth: { type: String, default: '150px' },
    dense: { type: Boolean, default: false },
    singleLine: { type: Boolean, default: false },
    hideDetails: { type: [Boolean, String], default: false },
    solo: { type: Boolean, default: false },
    rounded: { type: Boolean, default: false },
    prependIcon: { type: String, default: 'tl-calendar' },
  },
  data: () => ({
    menu: false,
    dateTextField: null,
  }),
  computed: {
    date: {
      get() {
        if (!this.value) {
          return this.required ? this.moment().format('YYYY-MM-DD') : ''
        }
        return this.moment(this.value).format('YYYY-MM-DD')
      },
      set(newVal) {
        this.$emit('input', this.updateDate(this.value, newVal))
      },
    },
    rules() {
      return this.required ? [ruleDate(this.format), ruleRequired] : [ruleDate(this.format)]
    },
  },
  watch: {
    date: {
      immediate: true,
      handler() {
        this.dateTextField = this.date ? this.moment(this.date, 'YYYY-MM-DD').format(this.format) : ''
      },
    },
  },
  methods: {
    onBlurTextfield() {
      if (!this.$refs.textfield.valid) return
      if (!this.dateTextField && !this.required) {
        this.date = ''
      } else {
        let parsed = this.moment(this.dateTextField, this.format)
        if (parsed.isValid()) this.date = parsed.format('YYYY-MM-DD')
      }
    },
    updateDate(dateTime, date) {
      if (!date) return ''
      let old = dateTime ? this.moment(dateTime) : this.moment()
      date = date || old.format('YYYY-MM-DD')
      let time = old.format('HH:mm')
      let parsed = this.moment(date + 'T' + time)
      let result = parsed.isValid() ? parsed : old
      return result.toISOString()
    },
  },
}
</script>
