export default {
  data: () => ({
    paginationPage: 1,
    paginationItemsPerPage: 10,
    paginationOrderBy: ['updated_at'],
    paginationOrderByDesc: [false],
    paginationItemsTotal: 0,
    paginationItemsPerPageOptions: [10, 20, 50, 100],
  }),
  computed: {
    paginationFetchParams() {
      return {
        page: this.paginationPage,
        limit: this.paginationItemsPerPage,
        orderBy: this.paginationOrderBy[0],
        orderByType: this.paginationOrderByDesc[0] ? 'desc' : 'asc',
      }
    },
    paginationOptionsTable: {
      get() {
        return {
          page: this.paginationPage,
          itemsPerPage: this.paginationItemsPerPage,
          sortBy: this.paginationOrderBy,
          sortDesc: this.paginationOrderByDesc,
        }
      },
      set(newVal) {
        this.paginationItemsPerPage = newVal.itemsPerPage
        this.paginationPage = newVal.page
        this.paginationOrderBy = newVal.sortBy
        this.paginationOrderByDesc = newVal.sortDesc
      },
    },
  },
}
