<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs">
        <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-cog</v-icon></v-btn>
      </slot>
    </template>
    <v-card>
      <v-card-title>Customize table</v-card-title>
      <v-card-subtitle>Select columns to show</v-card-subtitle>
      <v-list subheader>
        <v-list-item
          v-for="(header, index) in headersToCustomize"
          :key="index"
          @click="toggleHeaderEnabled(header.value)"
        >
          <v-list-item-action>
            <v-switch :input-value="headersEnabledValues.includes(header.value)" color="primary" @click.prevent="" />
          </v-list-item-action>
          <v-list-item-title v-text="header.text" />
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    headers: { type: Array, default: () => [] },
    headersEnabled: { type: Array, default: () => [] },
    headersAlwaysValues: { type: Array, default: () => [] },
    storageKey: { type: String, default: null },
  },
  computed: {
    headersToCustomize() {
      return this.headers.filter(header => !(this.headersAlways || []).includes(header.value))
    },
    headersToCustomizeValues() {
      return this.headersToCustomize.map(header => header.value)
    },
    headersEnabledValues() {
      return this.headersEnabled.map(header => header.value)
    },
  },
  mounted() {
    this.initHeadersEnabled()
  },
  methods: {
    initHeadersEnabled() {
      let storage = localStorage.getItem(this.storageKey)
      let headersSavedValues = (storage && JSON.parse(storage)) || this.headersToCustomizeValues
      let headersToShowValues = [...headersSavedValues, ...this.headersAlwaysValues]

      let newHeadersEnabled = this.headers.filter(header => headersToShowValues.includes(header.value))
      this.$emit('update:headers-enabled', newHeadersEnabled)
    },
    toggleHeaderEnabled(headerValue) {
      let values = this.headersEnabledValues || []
      const index = values.indexOf(headerValue)
      index > -1 ? values.splice(index, 1) : values.push(headerValue)
      localStorage.setItem(this.storageKey, JSON.stringify(values))

      let newHeadersEnabled = this.headers.filter(header => values.includes(header.value))
      this.$emit('update:headers-enabled', newHeadersEnabled)
    },
  },
}
</script>
