<template>
  <v-container>
    <v-card class="mx-auto mb-4" :max-width="$vuetify.breakpoint.mdAndUp ? '1000' : '500'">
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <div class="title text--primary">Booking interface</div>
            This section allows you to customize the customer-side of the booking interface.
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="subtitle-2 text--primary">Booking page introduction</div>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">
            Tell your customers why and how you work with a reservations system. This text is shown on the reservations
            page.
          </v-col>

          <v-col cols="12" md="6">
            <v-textarea
              v-model="reservationSettings.explanation"
              label="Introduction text"
              placeholder="We can only accommodate a limited number of people"
              outlined
              rows="5"
              auto-grow
              class="mb-n4"
            />
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="subtitle-2 text--primary">Required checkbox</div>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">Add a custom checkbox to the booking dialog and require customers to tick it.</v-col>
          <v-col cols="12" md="6">
            <v-textarea
              v-model="reservationSettings.reservationCheckbox"
              label="Booking checkbox label (optional)"
              placeholder="I do not have any health issues"
              hint="Leave empty to disable"
              outlined
              rows="5"
              auto-grow
              class="mb-n4"
            />
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="subtitle-2 text--primary">Membership numbers</div>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">
            <div>
              Setting membership numbers enabled allows you to mark time-slots to be accessible for members only. For
              those slots time-slots customers will be required to enter their number upon booking.
            </div>
          </v-col>
          <v-col cols="12" md="6" class="mt-n2">
            <v-list-item @click="toggleClientNumber" ripple>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1 white--text">tl-card-account-details-star</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Enable membership numbers</v-list-item-title>
                <v-list-item-subtitle>Shows the checkbox in the slot editor</v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action style="max-width: 50px">
                <v-switch v-model="reservationSettings.clientNumber" color="primary" @click.prevent="" />
              </v-list-item-action>
            </v-list-item>

            <template v-if="reservationSettings.clientNumber">
              <v-text-field
                v-model="reservationSettings.clientNumberLabel"
                label="Text field label"
                placeholder="Client number"
                outlined
                class="mt-4"
              />

              <v-textarea
                v-model="reservationSettings.clientNumberHint"
                label="Text field hint"
                placeholder="Explain customers where to find the number"
                hide-details
                outlined
                rows="2"
                auto-grow
              />
            </template>
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="subtitle-2 text--primary">Available number of spots</div>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">
            This option allows you to hide the number of available spots if it is greater than the allowed number of
            people per booking
          </v-col>
          <v-col cols="12" md="6" class="mt-n2">
            <v-list-item @click="toggleHideCapacity" ripple>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1 white--text">tl-account-question</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Hide available number of spots</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action style="max-width: 50px">
                <v-switch v-model="reservationSettings.hideCapacity" color="primary" @click.prevent="" />
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="subtitle-2 text--primary">Enable self-checkout</div>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">
            Allow users to check out themselves from within the app.
          </v-col>
          <v-col cols="12" md="6" class="mt-n2">
            <v-list-item @click="toggleSelfCheckout" ripple>
              <v-list-item-avatar>
                <v-icon class="grey lighten-1 white--text">tl-home-export-outline</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Enable self-checkout</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action style="max-width: 50px">
                <v-switch v-model="gym.reservations_self_checkout_enabled" color="primary" @click.prevent="" />
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mx-auto mb-4" :max-width="$vuetify.breakpoint.mdAndUp ? '1000' : '500'">
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <div class="title text--primary">Booking restrictions</div>
            Customers are allowed to book at most one spot per area per day. This section allows you to add more
            restrictions for your clients.
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="subtitle-2 text--primary">Add participants</div>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">
            <p>
              Upon booking, customers can be enabled to book for their climbing partner too. Please note: this allows
              them to omit the booking restrictions below by using their two email-adresses alternately.
            </p>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="additionalParticipants"
              label="Allow customers to add"
              suffix="additional participants"
              type="number"
              outlined
              hide-details
            />
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="subtitle-2 text--primary">Release of new time-slots</div>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">
            <p>Decide what moment time-slots will become available for booking.</p>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :value="gym.reservations_book_advance_days"
              @input="setGymProp('reservations_book_advance_days', $event || 1)"
              label="Customers can book at most"
              suffix="days in advance"
              type="number"
              outlined
              hide-details
            />

            <v-radio-group
              :value="!!gym.reservations_open_slots_at_fixed_time"
              @change="setGymProp('reservations_open_slots_at_fixed_time', $event)"
            >
              <template v-slot:label>
                <div>Time from which a slot can be booked:</div>
              </template>
              <v-radio
                :label="
                  `Release each slot at the same time it starts, but ${gym.reservations_book_advance_days} days earlier`
                "
                :value="false"
                color="primary"
              />
              <v-radio label="Release all slots of a day at the same time" :value="true" color="primary" />
            </v-radio-group>

            <div v-if="gym.reservations_open_slots_at_fixed_time">
              Release all slots daily at
              <v-menu
                ref="timePickerMenu"
                v-model="timePickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="time"
                    label="Time"
                    style="max-width: 120px;"
                    class="d-inline-block ml-2"
                    outlined
                    readonly
                    v-on="on"
                    hide-details
                  />
                </template>
                <v-time-picker
                  v-if="timePickerMenu"
                  v-model="time"
                  full-width
                  format="24hr"
                  @click:minute="$refs.timePickerMenu.save(time)"
                />
              </v-menu>
            </div>
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="subtitle-2 text--primary">Restrict the number of bookings</div>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">
            Restrict how ofter customers are allowed to book at your gym within a given time range.
          </v-col>
          <v-col cols="12" md="6">
            <v-checkbox
              v-model="bookingsInAdvanceEnabled"
              color="primary"
              label="Restrict the allowed number of future bookings"
              class="mt-n2"
            />
            <v-text-field
              :value="gym.reservations_bookings_advance_max"
              @input="setGymProp('reservations_bookings_advance_max', $event || 0)"
              label="Allow no more than"
              suffix="bookings in advance"
              type="number"
              outlined
              :disabled="!bookingsInAdvanceEnabled"
            />

            <v-checkbox
              v-model="bookingsPerDaysEnabled"
              color="primary"
              label="Restrict customers to max X bookings in Y days"
            />

            <v-row align-center class="my-n3">
              <v-col cols="6">
                <v-text-field
                  :value="gym.reservations_bookings_per_days_bookings"
                  @input="setGymProp('reservations_bookings_per_days_bookings', $event || 0)"
                  label="Max (X)"
                  suffix="bookings"
                  type="number"
                  outlined
                  :disabled="!bookingsPerDaysEnabled"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="gym.reservations_bookings_per_days_days"
                  @input="setGymProp('reservations_bookings_per_days_days', $event || 0)"
                  label="in (Y)"
                  suffix="days"
                  type="number"
                  outlined
                  :disabled="!bookingsPerDaysEnabled"
                />
              </v-col>
            </v-row>

            <v-checkbox
              v-model="unrestrictedLastMinutesEnabled"
              color="primary"
              label="Remove above restrictions for last-minute bookings"
            />
            <v-text-field
              :value="gym.reservations_unrestricted_last_minutes"
              @input="setGymProp('reservations_unrestricted_last_minutes', $event || 0)"
              label="Remove number-of-bookings restrictions"
              suffix="minutes in advance"
              type="number"
              outlined
              :disabled="!unrestrictedLastMinutesEnabled"
            />
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="subtitle-2 text--primary">Cancellation</div>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">
            Disable users from cancelling their booking last-minute. Please note this might result in more no-shows.
          </v-col>
          <v-col cols="12" md="6">
            <v-checkbox
              v-model="lastMinuteCancellationsEnabled"
              color="primary"
              label="Disable last-minute cancellations"
              class="mt-n2"
            />
            <v-text-field
              :value="gym.reservations_cancel_advance_hours"
              @input="setGymProp('reservations_cancel_advance_hours', $event || 0)"
              label="Allow customers to cancel at most"
              suffix="hours in advance"
              type="number"
              outlined
              hide-details
              :disabled="!lastMinuteCancellationsEnabled"
            />
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="subtitle-2 text--primary">Slot defaults</div>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">Customize default climbing time and check-in time.</v-col>
          <v-col cols="12" md="6">
            <v-text-field
              :value="reservationSettings.slotDuration"
              @input="updateSlotDuration($event)"
              label="Default slot duration"
              suffix="minutes"
              type="number"
              outlined
            />

            <v-text-field
              :value="reservationSettings.checkinDuration"
              @input="updateCheckinDuration($event)"
              label="Check-in duration"
              suffix="minutes"
              type="number"
              hint="Indicate a limit on the check-in time. Set to 0 to disable."
              outlined
              class="mb-n4"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mx-auto mb-4" :max-width="$vuetify.breakpoint.mdAndUp ? '1000' : '500'">
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <div class="title text--primary">Customize emails</div>
            TopLogger allows you to easily send emails from the reservations admin interface. This section allows you to
            add custom content to those emails.
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="subtitle-2 text--primary">Confirmation email</div>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">Add some custom content to the booking confirmation email.</v-col>
          <v-col cols="12" md="6">
            <v-textarea
              :value="gym.reservations_confirmation_email"
              @input="setGymProp('reservations_confirmation_email', $event)"
              label="Confirmation email message"
              outlined
              rows="3"
              placeholder="Append a personal message to the reservations email."
              auto-grow
            />
            <v-dialog v-model="confirmationPreviewDialog" width="500">
              <template v-slot:activator="{ on }">
                <v-btn block outlined v-on="on">Preview confirmation email</v-btn>
              </template>

              <v-card>
                <v-card-title class="headline" primary-title>Confirmation email preview</v-card-title>
                <v-card-text>
                  <p class="font-weight-bold">
                    Subject: {{ $t('reservations.emails.confirmation.subject', { gym_name: gym.name }) }}
                  </p>
                  <v-card outlined>
                    <v-card-title class="headline" primary-title>
                      {{ $t('reservations.emails.confirmation.confirmed') }}
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                      <p style="text-align: center;">
                        <span style="font-size: 1.3em; font-weight: bold">
                          {{
                            $t('reservations.emails.confirmation.activity_at', {
                              activity: '[Area name]',
                              gym_name: gym.name,
                            })
                          }}
                        </span>
                        <br />
                        <span style="font-size: 1.15em;">{{ moment().format('dddd, MMMM Do') }}</span>
                        <br />
                        <span style="font-size: 1.15em;">20:00 - 22:00</span><br />
                        {{ $t('reservations.emails.confirmation.checkin_time') }}: 20:00 - 20:10<br />
                        <span
                          style="padding: 8px 16px; color: #0d47a1; background-color: #e4e9f4; border-radius: 4px; line-height: 3em;"
                        >
                          Some details about this specific slot
                        </span>
                        <br />
                        [Names of participants]<br />
                      </p>

                      <v-divider />
                      <p class="mt-4">
                        [Customer name],<br />
                        {{
                          $t('reservations.emails.confirmation.confirmation', {
                            area_name: '[Area name]',
                            gym_name: gym.name,
                          })
                        }}<br />
                      </p>

                      <p v-if="gym.reservations_confirmation_email">{{ gym.reservations_confirmation_email }}</p>

                      <p>
                        {{ $t('reservations.emails.confirmation.manage_online') }}<br />
                        <a>{{ $t('reservations.emails.confirmation.manage_booking') }}</a>
                      </p>

                      <p>{{ $t('reservations.emails.confirmation.booking_number') }}: <i>#12345678</i></p>

                      <p>
                        {{ $t('reservations.emails.confirmation.thanks') }}<br />
                        {{ gym.name }}
                      </p>
                      <div class="text-right">
                        <v-img :src="require('./logo-email.png')" :max-width="303" />
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="confirmationPreviewDialog = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="subtitle-2 text--primary">No-show email</div>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">
            Add some custom content to the no-show email. This email can be send upon marking a customer as no-show.
          </v-col>
          <v-col cols="12" md="6">
            <v-textarea
              :value="gym.reservations_no_show_email"
              @input="setGymProp('reservations_no_show_email', $event)"
              label="No-show email message"
              outlined
              rows="3"
              auto-grow
              placeholder="Personal message about why you do not appreciate no-shows, and possibly explain consequences."
            />
            <v-dialog v-model="noShowPreviewDialog" width="500">
              <template v-slot:activator="{ on }">
                <v-btn block outlined v-on="on">Preview no-show email</v-btn>
              </template>

              <v-card>
                <v-card-title class="headline" primary-title>No-show email preview</v-card-title>
                <v-card-text>
                  <p class="font-weight-bold">Subject: {{ $t('reservations.emails.no_show.subject') }}</p>
                  <v-card outlined>
                    <v-card-title class="headline" primary-title>[Customer name],</v-card-title>
                    <v-card-text>
                      <p>
                        {{ $t('reservations.emails.no_show.you_are_marked', { gym_name: gym.name }) }}
                        {{
                          $t('reservations.emails.no_show.time_was', {
                            date: moment().format('dddd, MMMM Do'),
                            time_start: moment().format('H:mm'),
                            time_end: moment()
                              .add(15, 'm')
                              .format('H:mm'),
                          })
                        }}
                      </p>
                      <p>
                        {{ gym.reservations_no_show_email }}
                      </p>
                      <p>{{ $t('reservations.emails.no_show.cancel') }}</p>
                      <p>{{ $t('reservations.emails.no_show.questions') }}</p>
                      <div class="text-right">
                        <v-img :src="require('./logo-email.png')" :max-width="303" />
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="noShowPreviewDialog = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>

        <v-divider class="my-3" />

        <div class="subtitle-2 text--primary">Check-out reminder email</div>
        <v-row class="mt-n3">
          <v-col cols="12" md="6">
            Add some custom content to the check-out reminder email. This email can be send when from the admin
            booking-interface when a client is checked in.
          </v-col>
          <v-col cols="12" md="6">
            <v-textarea
              :value="gym.reservations_checkout_reminder_email"
              @input="setGymProp('reservations_checkout_reminder_email', $event)"
              label="Message in check out reminder email"
              outlined
              rows="3"
              auto-grow
              placeholder="Personal addition, e.g. about how you wish to be contacted if the customer already went home."
            />
            <v-dialog v-model="checkoutReminderPreviewDialog" width="500">
              <template v-slot:activator="{ on }">
                <v-btn block outlined v-on="on">Preview checkout reminder email</v-btn>
              </template>

              <v-card>
                <v-card-title class="headline" primary-title>Check out reminder email preview</v-card-title>
                <v-card-text>
                  <p class="font-weight-bold">Subject: {{ $t('reservations.emails.checkoutReminder.subject') }}</p>
                  <v-card outlined>
                    <v-card-title class="headline" primary-title>[Customer name],</v-card-title>
                    <v-card-text>
                      <p>
                        {{ $t('reservations.emails.checkoutReminder.reminder', { gym_name: gym.name }) }}
                        {{
                          $t('reservations.emails.checkoutReminder.time_is', {
                            date: moment().format('dddd, MMMM Do'),
                            time_end: moment()
                              .add(15, 'm')
                              .format('H:mm'),
                          })
                        }}
                      </p>
                      <p v-text="gym.reservations_checkout_reminder_email" />
                      <p>{{ $t('reservations.emails.no_show.questions') }}</p>
                      <div class="text-right">
                        <v-img :src="require('./logo-email.png')" :max-width="303" />
                      </div>
                    </v-card-text>
                  </v-card>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="checkoutReminderPreviewDialog = false">Close</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <tl-reservation-admin-settings-areas />
  </v-container>
</template>

<script>
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import tlAutosaveMixin from '@/services/autosave/tl-auto-save.mixin'
import tlSaveBtnMixin from '@/components/shared/save-button.mixin'
import tlReservationAdminSettingsAreas from './tl-reservation-admin-settings-areas'
import { mapState } from 'vuex'

export default {
  mixins: [toolbarMixin, tlAutosaveMixin, tlSaveBtnMixin],
  components: {
    tlReservationAdminSettingsAreas,
  },
  data: () => ({
    tlToolbarTitle: 'Reservations settings',
    reservationSettings: {},
    reservationSettingsDefault: {
      version: 1,
      maxClients: 0,
      slotDuration: 120,
      checkinDuration: 15,
    },
    timePickerMenu: false,
    noShowPreviewDialog: false,
    confirmationPreviewDialog: false,
    checkoutReminderPreviewDialog: false,
  }),
  computed: {
    ...mapState(['user', 'gym']),
    recordsToSave() {
      return [this.gym]
    },
    tlToolbarBtnLeft() {
      return {
        icon: 'tl-arrow-back',
        action: () => {
          this.$router.pushSmart({ name: 'admin.reservations' }) // eslint-disable-line
        },
      }
    },
    tlToolbarButtons() {
      return [this.tlSaveBtn]
    },
    reservationSettingsJson() {
      return JSON.stringify(this.reservationSettings)
    },
    additionalParticipants: {
      get() {
        return (this.gym.reservations_spots_per_booking || 1) - 1
      },
      set(newVal) {
        this.setGymProp('reservations_spots_per_booking', parseInt(newVal) + 1)
      },
    },
    time: {
      get() {
        return this.moment(this.gym.reservations_open_slots_at_time).format('HH:mm')
      },
      set(newVal) {
        let newMoment = this.updateTime(this.gym.reservations_open_slots_at_time, newVal)
        this.setGymProp('reservations_open_slots_at_time', newMoment)
      },
    },
    bookingsInAdvanceEnabled: {
      get() {
        return !!this.gym.reservations_bookings_advance_max
      },
      set(enabled) {
        this.setGymProp('reservations_bookings_advance_max', enabled ? 2 : 0)
      },
    },
    bookingsPerDaysEnabled: {
      get() {
        return !!this.gym.reservations_bookings_per_days_bookings && !!this.gym.reservations_bookings_per_days_days
      },
      set(enabled) {
        this.setGymProp('reservations_bookings_per_days_bookings', enabled ? 3 : 0)
        this.setGymProp('reservations_bookings_per_days_days', enabled ? 7 : 0)
      },
    },
    unrestrictedLastMinutesEnabled: {
      get() {
        return !!this.gym.reservations_unrestricted_last_minutes
      },
      set(enabled) {
        this.setGymProp('reservations_unrestricted_last_minutes', enabled ? 60 : 0)
      },
    },
    lastMinuteCancellationsEnabled: {
      get() {
        return !!this.gym.reservations_cancel_advance_hours
      },
      set(enabled) {
        this.setGymProp('reservations_cancel_advance_hours', enabled ? 1 : 0)
      },
    },
  },
  created() {
    this.initReservationSettings()
  },
  watch: {
    reservationSettingsJson() {
      this.setGymProp('revervation_settings_json', this.reservationSettingsJson)
    },
  },
  methods: {
    setGymProp(prop, value) {
      this.gym.$update({ [prop]: value })
    },
    initReservationSettings() {
      const json = this.gym.revervation_settings_json
      this.reservationSettings = (json && JSON.parse(json)) || Object.assign({}, this.reservationSettingsDefault)
    },
    updateSlotDuration(newVal) {
      this.$set(this.reservationSettings, 'slotDuration', newVal ? parseInt(newVal) : 120)
    },
    updateCheckinDuration(newVal) {
      this.$set(this.reservationSettings, 'checkinDuration', newVal ? parseInt(newVal) : 0)
    },
    toggleGuestEnabled() {
      this.setGymProp('reservations_guest_enabled', !this.gym.reservations_guest_enabled)
    },
    toggleClientNumber() {
      this.$set(this.reservationSettings, 'clientNumber', !this.reservationSettings.clientNumber)
    },
    toggleHideCapacity() {
      this.$set(this.reservationSettings, 'hideCapacity', !this.reservationSettings.hideCapacity)
    },
    toggleSelfCheckout() {
      this.setGymProp('reservations_self_checkout_enabled', !this.gym.reservations_self_checkout_enabled)
    },
    updateTime(dateTime, time) {
      let date = this.moment(dateTime).format('YYYY-MM-DD')
      time = time || this.moment(dateTime).format('HH:mm')
      return this.moment(date + 'T' + time).toISOString()
    },
  },
}
</script>
