<template>
  <v-list-item class="body-2">
    <v-row no-gutters align="center">
      <v-col cols="6" sm="9">
        <v-row no-gutters align="center">
          <v-col cols="12" sm="4">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on" :class="{ 'grey--text': reservation.reservation_id }">
                  #{{ reservation.booking_number }}
                </span>
              </template>
              <span>Reserved {{ moment(reservation.created_at).fromNow() }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="12" sm="3" v-if="reservationSettings.clientNumber">
            {{ reservation.client_number }}
          </v-col>
          <v-hover v-slot:default="{ hover }" close-delay="400">
            <v-col cols="12" :sm="reservationSettings.clientNumber ? 5 : 8" style="user-select: text">
              <div>{{ reservation.first_name }} {{ reservation.last_name }}</div>
              <v-expand-transition>
                <div v-show="hover" class="grey--text">{{ reservation.email || 'No email available' }}</div>
              </v-expand-transition>
            </v-col>
          </v-hover>
        </v-row>
      </v-col>
      <v-col cols="6" sm="3">
        <v-btn v-if="!status.text" small color="primary" :loading="updating" @click="checkInOut()">
          Check in
        </v-btn>
        <span v-else>{{ status.text }}</span>
      </v-col>
    </v-row>

    <v-list-item-action class="my-0">
      <v-menu left offset-y min-width="250">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" :loading="removing"><v-icon>tl-more-vert</v-icon></v-btn>
        </template>
        <v-list>
          <v-list-item v-if="reservation.in_at && !reservation.out_at" @click="checkInOut()">
            <v-list-item-icon><v-icon>tl-account-arrow-right</v-icon></v-list-item-icon>
            <v-list-item-title>Check out</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="reservation.in_at" @click="undoCheckInOut()">
            <v-list-item-icon><v-icon>tl-restore</v-icon></v-list-item-icon>
            <v-list-item-title>Undo check-{{ reservation.out_at ? 'out' : 'in' }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="reservation.in_at && !reservation.out_at"
            :disabled="!reservation.email"
            @click="sendCheckoutReminder()"
          >
            <v-list-item-icon><v-icon>tl-reminder</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Send checkout reminder</v-list-item-title>
              <v-list-item-subtitle v-if="!reservation.email">No email available</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="(reservation.no_show || !reservation.in_at) && !reservation.cancelled_at"
            @click="toggleNoShow()"
          >
            <v-list-item-icon><v-icon>tl-account-off</v-icon></v-list-item-icon>
            <v-list-item-title>{{ reservation.no_show ? 'Undo mark' : 'Mark' }} as no show</v-list-item-title>
          </v-list-item>
          <template v-if="!reservation.in_at && !reservation.no_show">
            <v-divider v-if="!reservation.cancelled_at" />
            <v-list-item v-if="!reservation.cancelled_at" @click="cancel()">
              <v-list-item-icon><v-icon>tl-cancel</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Cancel reservation</v-list-item-title>
                <v-list-item-subtitle v-if="reservation.associates_count">
                  Including{{ reservation.associates_count > 1 ? ` ${reservation.associates_count}` : '' }}
                  {{ 'associate' | pluralize(reservation.associates_count) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else @click="remove()">
              <v-list-item-icon><v-icon>tl-delete</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Remove reservation</v-list-item-title>
                <v-list-item-subtitle v-if="reservation.associates_count">
                  Including{{ reservation.associates_count > 1 ? ` ${reservation.associates_count}` : '' }}
                  {{ 'associate' | pluralize(reservation.associates_count) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { mapState } from 'vuex'
import axios from '@/services/axios'
import errorService from '@/services/error-service'

export default {
  props: {
    timeSlot: { type: Object, default: () => ({}) },
    reservation: { type: Object, default: () => ({}) },
  },
  data: () => ({
    removing: false,
    updating: false,
  }),
  computed: {
    ...mapState(['gym']),
    status() {
      if (this.reservation.cancelled_at) return { text: 'Cancelled', icon: 'account-off' }
      if (this.reservation.no_show) return { text: 'No-show', icon: 'account-off', class: 'error' }
      if (this.reservation.out_at) return { text: 'Checked out', icon: 'account-arrow-right' }
      if (this.reservation.in_at) return { text: 'Checked in', icon: 'account-check' }
      return { text: '', icon: 'account' }
    },
    reservationSettings() {
      let settings = this.gym.revervation_settings_json && JSON.parse(this.gym.revervation_settings_json)
      return settings || {}
    },
  },
  methods: {
    checkInOut() {
      let prop = this.reservation.in_at ? 'out_at' : 'in_at'
      this.updateReservation({ [prop]: this.moment().toISOString() })
    },
    undoCheckInOut() {
      let prop = this.reservation.out_at ? 'out_at' : 'in_at'
      this.updateReservation({ [prop]: null })
    },
    sendCheckoutReminder() {
      if (!this.reservation.email) return
      axios
        .put(`/gyms/${this.gym.id}/reservations/${this.reservation.id}/send_checkout_reminder`)
        .then(() => this.$store.dispatch('toast/success', 'A reminder email has been send'))
        .catch(errorService.toast)
    },
    async toggleNoShow() {
      let newVal = !this.reservation.no_show
      await this.updateReservation({
        no_show: newVal,
        in_at: null,
        out_at: null,
      })

      if (!newVal) return // Don't mail if this toggle was to undo the no-show flagging
      if (!this.reservation.email) return
      let doSend = await this.$store.dispatch('dialog/confirm', {
        title: 'Send email?',
        text: 'Do you want to send this user your notification/warning email?',
        cancel: 'No',
        ok: 'Yes',
      })
      if (!doSend) return
      axios
        .put(`/gyms/${this.gym.id}/reservations/${this.reservation.id}/send_no_show`)
        .then(() => this.$store.dispatch('toast/success', 'No-show email has been send'))
        .catch(errorService.toast)
    },
    updateReservation(update) {
      this.updating = true
      this.$store
        .dispatch('reservations/updateReservation', { reservation: this.reservation, update })
        .finally(() => (this.updating = false))
    },
    async cancel() {
      let confirmationReturn
      if (this.reservation.email) {
        confirmationReturn = await this.$store.dispatch('dialog/prompt', {
          title: 'Cancel booking?',
          text: 'Are you sure? A cancellation email will be send to the customer',
          cancel: 'Cancel',
          ok: 'Cancel booking',
          maxWidth: 400,
          outlined: true,
          label: 'Add an explanation to the email (optional)',
        })
        if (!confirmationReturn) return
      } else {
        confirmationReturn = await this.$store.dispatch('dialog/confirm', {
          title: 'Cancel reservation?',
          text: 'Are you sure?',
          cancel: 'No',
          ok: 'Yes',
          maxWidth: 400,
          label: 'Explanation',
        })
        if (!confirmationReturn) return
      }

      let putData = {}
      if (confirmationReturn !== true) {
        putData.cancellation_explanation = confirmationReturn
      }

      this.removing = true
      axios
        .put(`/gyms/${this.gym.id}/reservations/${this.reservation.id}/cancel`, putData)
        .then(() => {
          this.timeSlot.reservations
            .filter(r => [r.id, r.reservation_id].includes(this.reservation.id))
            .forEach(reservation => (reservation.cancelled_at = this.moment().toISOString()))
          this.$store.dispatch('toast/success', 'Reservation cancelled')
          this.$store.dispatch('reservations/fetchSlots')
        })
        .catch(errorService.toast)
        .finally(() => (this.removing = false))
    },
    async remove() {
      let confirmed = await this.$store.dispatch('dialog/confirm', {
        title: 'Remove reservation?',
        text: 'Are you sure?',
        ok: 'Remove',
      })
      if (!confirmed) return

      this.removing = true
      axios
        .delete(`/gyms/${this.gym.id}/reservations/${this.reservation.id}`)
        .then(() => {
          this.timeSlot.reservations = this.timeSlot.reservations.filter(
            r => ![r.id, r.reservation_id].includes(this.reservation.id)
          )
          this.$store.dispatch('toast/success', 'Reservation removed')
          this.$store.dispatch('reservations/fetchSlots')
        })
        .catch(errorService.toast)
        .finally(() => (this.removing = false))
    },
  },
}
</script>
<style lang="scss" scoped></style>
