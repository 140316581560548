<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row no-gutters align="center">
        <v-col cols="12" :sm="showArea ? 3 : 4">
          {{ moment(timeSlot.start_at).format('LT') }} &mdash; {{ moment(timeSlot.end_at).format('LT') }}

          <v-tooltip v-if="!timeSlot.live" bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small color="warning" class="mb-1 mr-1">tl-visibility-off</v-icon>
            </template>
            <span>This slot is not visible to customers</span>
          </v-tooltip>

          <v-tooltip v-if="timeSlot.require_password" bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small class="mb-1">tl-lock</v-icon>
            </template>
            <span>This slot can only be booked with a password</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12" v-if="showArea" sm="3">{{ areaName }}</v-col>
        <v-col cols="12" :sm="showArea ? 3 : 4" class="bookings mb-3">
          {{ bookingsCount }}/{{ timeSlot.spots }} booked
          <v-icon v-if="bookingPercentage >= 100" small>tl-lock</v-icon>
          <div class="bookings__bar">
            <div class="bookings__indicator" :style="{ width: `${bookingPercentage}%` }" />
          </div>
        </v-col>
        <v-col cols="12" :sm="showArea ? 3 : 4" :class="`${durationColor}--text`" class="text--lighten-1">
          <span v-if="slotStarted && checkedInCount" class="d-flex align-center">
            <v-icon size="18" :color="durationColor" class="mr-1">tl-alarm</v-icon>
            {{ durationText }}
          </span>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <p v-if="timeSlot.details" class="info--text">{{ timeSlot.details }}</p>
      <v-card outlined>
        <v-list dense subheader class="pb-0">
          <v-subheader class="px-4">
            <v-row no-gutters class="mr-12">
              <v-col cols="6" sm="3">Booking</v-col>
              <v-col cols="2" v-if="reservationSettings.clientNumber && $vuetify.breakpoint.smAndUp">Client nr</v-col>
              <v-col :cols="reservationSettings.clientNumber ? 4 : 6" v-if="$vuetify.breakpoint.smAndUp">Name</v-col>
              <v-col cols="6" sm="3">Status</v-col>
            </v-row>
          </v-subheader>
          <template v-for="(reservation, index) in filteredReservations">
            <v-divider :key="-index" />
            <tl-reservations-admin-reservation :key="reservation.id" :reservation="reservation" :time-slot="timeSlot" />
          </template>
          <template v-if="!filteredReservations.length">
            <v-divider />
            <div class="pa-4 d-flex align-center">
              <div class="text--grey caption">No bookings available</div>
              <v-spacer />
              <v-btn small text :loading="slotsLoading" @click="refresh()">
                <v-icon small>tl-autorenew</v-icon>Refresh
              </v-btn>
            </div>
          </template>
        </v-list>
        <v-divider />
        <v-card-actions>
          <v-btn small text color="primary" @click="addBooking()">Add booking</v-btn>
        </v-card-actions>
      </v-card>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { mapState } from 'vuex'
import tlReservationsAdminReservation from './tl-reservations-admin-reservation'
import tlReservationsUpdateIntervalMixin from './tl-reservations-update-interval.mixin'

export default {
  mixins: [tlReservationsUpdateIntervalMixin],
  components: {
    tlReservationsAdminReservation,
  },
  props: {
    timeSlot: { type: Object, default: () => ({}) },
    filteredReservations: { type: Array, default: () => [] },
  },
  data: () => ({
    expanded: false,
    duration: null,
    slotStarted: false,
  }),
  computed: {
    ...mapState(['gym']),
    ...mapState('reservations', ['areas', 'slotsLoading']),
    reservationSettings() {
      let settings = this.gym.revervation_settings_json && JSON.parse(this.gym.revervation_settings_json)
      return settings || {}
    },
    durationText() {
      if (this.duration <= 0) return '00:00'
      return this.moment.utc(Math.abs(this.duration)).format('HH:mm')
    },
    durationColor() {
      if (this.duration <= 0) return 'error'
      if (this.duration < 600000) return 'warning' // Last ten minutes
      return ''
    },
    showArea() {
      return this.areas.length > 1
    },
    areaName() {
      let area = this.areas.find(area => area.id == this.timeSlot.reservation_area_id)
      return area && area.name
    },
    bookingsCount() {
      return this.timeSlot.reservations.filter(r => !r.no_show && !r.cancelled_at).length
    },
    bookingPercentage() {
      return this.timeSlot.spots ? (this.bookingsCount / this.timeSlot.spots) * 100 : 0
    },
    checkedInCount() {
      return this.timeSlot.reservations.filter(r => r.in_at && !r.out_at).length
    },
  },
  methods: {
    updateIntervalFunction() {
      let diff = this.moment(this.timeSlot.end_at).diff()
      this.duration = this.moment.duration(diff).as('milliseconds')
      this.slotStarted = this.moment().isAfter(this.timeSlot.start_at)
    },
    refresh() {
      this.$store.dispatch('reservations/fetchSlots')
    },
    addBooking() {
      this.$emit('book')
    },
  },
}
</script>

<style lang="sass">
.bookings
  position: relative
  &__bar
    position: absolute
    width: 100px
    height: 4px
    border-radius: 2px
    background-color: var(--v-grey-lighten1)
    margin-top: 4px
  &__indicator
    position: absolute
    height: 4px
    border-radius: 2px
    background-color: var(--v-primary-base)
</style>
