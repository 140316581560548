<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="7">
        <v-card v-if="areasLoading || areas.length != 1" class="mt-4">
          <v-card-text v-if="areasLoading" class="d-flex justify-center py-12">
            <v-progress-circular color="primary" indeterminate size="40" />
          </v-card-text>
          <v-card-text v-if="areas.length > 1" class="py-12">
            <v-select
              v-model="selectedArea"
              :items="areas"
              label="Select your area"
              item-text="name"
              return-object
              hide-details
            />
          </v-card-text>
          <v-card-text v-if="!areasLoading && !areas.length" class="d-flex flex-column py-12">
            <div class="title text-center mb-4">You have not set-up any areas</div>
            <v-btn color="primary" class="mx-auto" :to="{ name: 'admin.reservations.settings' }">Manage areas</v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="area" justify="center">
      <v-col cols="12" md="7">
        <v-date-picker
          :value="date"
          :locale="$i18n.locale"
          :first-day-of-week="moment.localeData()._week.dow"
          :landscape="$vuetify.breakpoint.smAndUp"
          reactive
          full-width
          color="primary"
          :picker-date.sync="monthStatsPickerDate"
          :events="monthStatsEvents"
          @change="$store.commit('reservations/setDate', $event)"
        />

        <v-card v-if="area && slots.length" :loading="slotsLoading" class="mt-4">
          <v-toolbar>
            <v-toolbar-title>Available spots distribution</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <tl-reservations-admin-graph-legend />
            <tl-reservations-admin-graph :area-id="area.id" />
          </v-card-text>
        </v-card>

        <v-card class="mt-4" :loading="slotsLoading">
          <v-toolbar :dark="!!selection.length">
            <v-list-item-action v-if="slots.length">
              <v-checkbox v-model="allSelected" color="primary" />
            </v-list-item-action>

            <v-toolbar-title>
              {{ selection.length ? `${selection.length} slots selected` : 'Available time slots' }}
            </v-toolbar-title>

            <v-spacer />

            <v-scale-transition>
              <v-btn v-if="selection.length" icon @click="editSlotsSelected()">
                <v-icon>tl-edit</v-icon>
              </v-btn>
            </v-scale-transition>

            <v-menu left offset-y min-width="250" v-if="selection.length">
              <template v-slot:activator="{ on }">
                <v-btn icon :loading="cloning || removingAll" v-on="on"><v-icon>tl-more-vert</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item @click="cloneSlotsDialog = true">
                  <v-list-item-icon><v-icon>tl-calendar-refresh</v-icon></v-list-item-icon>
                  <v-list-item-title>Clone slots to other days</v-list-item-title>
                </v-list-item>
                <v-divider />
                <v-list-item @click="removeAll">
                  <v-list-item-icon><v-icon>tl-delete</v-icon></v-list-item-icon>
                  <v-list-item-title>Remove selected</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn v-else icon @click="editSlot({})">
              <v-icon>tl-add</v-icon>
            </v-btn>
          </v-toolbar>
          <v-list two-line>
            <div v-if="!slots.length && !slotsLoading" class="d-flex flex-column my-12">
              <div class="title text--grey text-center mb-4">No time slots available</div>
              <v-btn color="primary" class="mx-auto" @click="editSlot({})">Add slots</v-btn>
            </div>
            <v-list-item v-for="slot in slots" :key="slot.id" @click="toggleSelect(slot)">
              <v-list-item-action>
                <v-checkbox :value="selection.includes(slot)" color="primary" @click.prevent />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ moment(slot.start_at).format('LT') }} &mdash; {{ moment(slot.end_at).format('LT') }}
                  <v-chip small outlined color="grey" class="ml-4">
                    {{ moment(slot.end_at).preciseDiff(slot.start_at) }}
                  </v-chip>
                  <v-chip small outlined color="info" class="ml-2" v-if="slot.require_client_number">
                    Client number required
                  </v-chip>
                  <v-chip small outlined color="error" class="ml-2" v-if="slot.password">
                    <v-icon small left>tl-lock</v-icon>Password
                  </v-chip>
                  <v-chip v-if="!slot.live" small color="warning" class="ml-2">Not visible to customers</v-chip>
                </v-list-item-title>
                <v-list-item-subtitle v-if="slot.details" class="info--text">{{ slot.details }}</v-list-item-subtitle>
                <v-list-item-subtitle>
                  {{ getActiveBookingsCount(slot) }} / {{ slot.spots }} booked
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon @click.stop="editSlot(slot)"><v-icon>tl-edit</v-icon></v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn icon :loading="removing == slot.id" @click.stop="deleteSlot(slot)">
                  <v-icon>tl-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <tl-reservations-admin-slot-edit-dialog ref="dialogEdit" />
          <tl-reservations-admin-slot-edit-bulk-dialog ref="dialogEditBulk" />
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="cloneSlotsDialog" persistent width="290px">
      <v-date-picker
        v-model="cloneSlotsDates"
        :locale="$i18n.locale"
        :first-day-of-week="moment.localeData()._week.dow"
        multiple
        scrollable
        show-current
        :min="today"
      >
        <v-spacer />
        <v-btn text color="primary" @click="closeCloneSlotsDialog">Cancel</v-btn>
        <v-btn text color="primary" :loading="cloning" :disabled="cloning" @click="cloneSlots">Clone slots</v-btn>
      </v-date-picker>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import axios from '@/services/axios'
import errorService from '@/services/error-service'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import reservationsStore from '../reservations.store'
import tlReservationsMonthStatsMixin from '../tl-reservations-month-stats.mixin'
import tlReservationsAdminSlotEditDialog from './tl-reservations-admin-slot-edit-dialog'
import tlReservationsAdminSlotEditBulkDialog from './tl-reservations-admin-slot-edit-bulk-dialog'
import tlReservationsAdminGraph from '../tl-reservations-admin-graph'
import tlReservationsAdminGraphLegend from '../tl-reservations-admin-graph-legend'

export default {
  mixins: [toolbarMixin, tlReservationsMonthStatsMixin],
  components: {
    tlReservationsAdminSlotEditDialog,
    tlReservationsAdminSlotEditBulkDialog,
    tlReservationsAdminGraph,
    tlReservationsAdminGraphLegend,
  },
  data: () => ({
    tlToolbarTitle: 'Manage time slots',
    today: null,
    removing: false,
    removingAll: false,
    cloning: false,
    cloneSlotsDialog: false,
    cloneSlotsDates: [],
    selection: [],
  }),
  computed: {
    ...mapState(['gym']),
    ...mapState('reservations', ['areas', 'areasLoading', 'area', 'date', 'slots', 'slotsLoading']),
    monthStatsAreaId() {
      return this.area && this.area.id
    },
    selectedArea: {
      get() {
        return this.area
      },
      set(area) {
        this.$store.commit('reservations/setArea', area)
      },
    },
    tlToolbarBtnLeft() {
      return {
        icon: 'tl-arrow-back',
        action: () => {
          this.$router.pushSmart({ name: 'admin.reservations' }) // eslint-disable-line
        },
      }
    },
    getActiveBookingsCount() {
      return slot => {
        return slot.reservations.filter(r => !r.no_show && !r.cancelled_at).length
      }
    },
    allSelected: {
      get() {
        return this.slots.length && this.slots.every(slot => this.selection.includes(slot))
      },
      set(selectAll) {
        this.selection = selectAll ? [...this.slots] : []
      },
    },
  },
  beforeCreate() {
    this.$store.registerModuleOnce('reservations', reservationsStore)
  },
  created() {
    this.today = this.moment().format('YYYY-MM-DD')
    this.$store.commit('reservations/setDate') // Resets the date to today
    this.$store.dispatch('reservations/fetchAreas')
    this.$store.dispatch('reservations/fetchSlots')
  },
  watch: {
    area() {
      this.updateSlots()
    },
    date() {
      this.showSlotsPassed = this.moment(this.date).isBefore(this.moment(), 'd')
      this.updateSlots()
    },
    slots() {
      this.selection = []
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('reservations/setArea')
    next()
  },
  methods: {
    updateSlots() {
      this.$store.commit('reservations/setSlots', [])
      this.$store.dispatch('reservations/fetchSlots')
    },
    async editSlot(slot) {
      let confirm = await this.$refs.dialogEdit.open(slot)
      if (!confirm) return
      this.$store.dispatch('reservations/fetchSlots')
      this.monthStatsFetch()
    },
    async editSlotsSelected() {
      if (this.selection.length == 1) return this.editSlot(this.selection[0])
      let confirm = await this.$refs.dialogEditBulk.open(this.selection)
      if (!confirm) return
      this.$store.dispatch('reservations/fetchSlots')
      this.monthStatsFetch()
    },
    async deleteSlot(slot) {
      if (this.getActiveBookingsCount(slot)) {
        let confirmed = await this.$store.dispatch('dialog/confirm', {
          title: 'Remove slot?',
          text: 'Are you sure? There are reservations for this slot that will be cancelled without notification.',
          ok: 'Remove',
        })
        if (!confirmed) return
      }

      this.removing = slot.id
      axios
        .delete(`/gyms/${this.$store.state.gym.id}/slots/${slot.id}`)
        .then(() => {
          this.$store.dispatch('toast/success', 'Slot removed')
          this.$store.dispatch('reservations/removeSlot', slot.id)
          this.$store.dispatch('reservations/fetchSlots')
          this.monthStatsFetch()
        })
        .catch(errorService.toast)
        .finally(() => (this.removing = null))
    },
    closeCloneSlotsDialog() {
      this.cloneSlotsDialog = false
      this.cloneSlotsDates = []
      this.monthStatsFetch()
    },
    updateDate(dateTime, date) {
      date = date || this.moment(dateTime).format('YYYY-MM-DD')
      let time = this.moment(dateTime).format('HH:mm')
      return this.moment(date + 'T' + time).toISOString()
    },
    async cloneSlots() {
      if (!this.cloneSlotsDates.length) {
        return this.$store.dispatch('toast/error', 'No date(s) selected')
      }

      let newSlots = []
      this.cloneSlotsDates.forEach(date => {
        let newSlotsDate = this.selection.map(slot => {
          // Get the difference in days for the start_at, and add it to each timestamp.
          // This assures slots that end on the next day are still cloned properly.
          let slotStart = this.moment(slot.start_at).startOf('day')
          let dateStart = this.moment(date).startOf('day')
          let diffDays = dateStart.diff(slotStart, 'days')
          return {
            ...slot,
            start_at: this.moment(slot.start_at)
              .add(diffDays, 'days')
              .toISOString(),
            end_at: this.moment(slot.end_at)
              .add(diffDays, 'days')
              .toISOString(),
            checkin_end_at: this.moment(slot.checkin_end_at)
              .add(diffDays, 'days')
              .toISOString(),
          }
        })
        newSlots = newSlots.concat(newSlotsDate)
      })

      this.cloning = true
      axios
        .post(`gyms/${this.gym.id}/slots/create_many`, { slots: newSlots })
        .then(() => {
          this.$store.dispatch('toast/success', 'Slots created')
          this.closeCloneSlotsDialog()
        })
        .catch(errorService.toast)
        .finally(() => (this.cloning = false))
    },
    async removeAll() {
      let hasReservations = this.selection.some(slot => slot.reservations.length)
      let dialog = {
        title: 'Remove slots?',
        text: 'Are you sure?',
        ok: 'Remove',
      }
      if (hasReservations)
        dialog.text += ' Some of these slots already have bookings. Those will be cancelled without notification.'
      let confirmed = await this.$store.dispatch('dialog/confirm', dialog)
      if (!confirmed) return

      this.removingAll = true
      axios
        .delete(`gyms/${this.gym.id}/slots/destroy_many`, { data: { ids: this.selection.map(s => s.id) } })
        .then(() => {
          this.$store.dispatch('toast/success', 'Slots deleted')
          this.$store.commit('reservations/setSlots', [])
          this.$store.dispatch('reservations/fetchSlots')
          this.monthStatsFetch()
        })
        .catch(errorService.toast)
        .finally(() => (this.removingAll = false))
    },
    toggleSelect(slot) {
      if (this.selection.includes(slot)) {
        this.selection = this.selection.filter(s => s.id != slot.id)
      } else {
        this.selection.push(slot)
      }
    },
  },
}
</script>
