<template>
  <v-row>
    <v-col cols="12" class="pt-0">
      <v-card class="py-3 px-6">
        <v-row>
          <v-col cols="4">
            <v-img :src="require('./users.png')" height="84" contain />
          </v-col>
          <v-col cols="8">
            <v-row no-gutters>
              <v-col cols="12" class="headline primary--text">Checked in</v-col>
              <v-col cols="12" class="display-3 grey--text">{{ checkedIns.length }}</v-col>
            </v-row>
          </v-col>
          <v-col cols="12" v-if="historyDay && checkedIns.length" class="grey--text">
            * Clients that were checked in at {{ moment(date).format('dddd, MMMM Do') }} and never checked out.
          </v-col>
          <template v-if="areas.length > 1">
            <v-col cols="6" v-for="stat in checkedInsByArea" :key="stat.area.id">
              <div cols="12" class="subtitle-1 primary--text">{{ stat.area.name }}</div>
              <div
                cols="12"
                class="display-1"
                :class="stat.count > stat.area.capacity ? 'warning--text' : 'grey--text'"
              >
                {{ stat.count }}<span class="body-1"> / {{ stat.area.capacity }}</span>
              </div>
            </v-col>
          </template>
          <v-col cols="12" class="pa-0">
            <v-card outlined class="pa-0">
              <v-btn text block @click="statsExpanded = !statsExpanded">
                Statistics
                <v-icon right>tl-chevron-{{ statsExpanded ? 'up' : 'down' }}</v-icon>
              </v-btn>
              <v-expand-transition>
                <div v-if="statsExpanded">
                  <v-divider />
                  <tl-reservations-admin-graph-legend />
                  <div v-for="area in areas" :key="`graph-area-${area.id}`" class="pa-2">
                    <v-divider />
                    <div class="body-1 text-center mt-2">{{ area.name }}</div>
                    <tl-reservations-admin-graph :area-id="area.id" />
                  </div>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" v-if="checkedIns.length">
      <v-card>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            placeholder="Search checked-in clients"
            prepend-icon="tl-search"
            clearable
            hide-details
          />
        </v-toolbar>
        <v-list two-line>
          <tl-reservations-admin-checkedin-item
            v-for="reservation in filteredCheckedinsPaginated"
            :key="reservation.id"
            :reservation="reservation"
          />
        </v-list>

        <tl-pagination-local
          :list="filteredCheckedins"
          @list-paginated="filteredCheckedinsPaginated = $event"
          :items-per-page="20"
        />
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import tlReservationsAdminCheckedinItem from './tl-reservations-admin-checkedin-item'
import tlReservationsAdminGraph from './tl-reservations-admin-graph'
import tlReservationsAdminGraphLegend from './tl-reservations-admin-graph-legend'
import tlPaginationLocal from '@/components/shared/tl-pagination-local'

export default {
  components: {
    tlReservationsAdminCheckedinItem,
    tlReservationsAdminGraph,
    tlReservationsAdminGraphLegend,
    tlPaginationLocal,
  },
  data: () => ({
    search: '',
    filteredCheckedinsPaginated: [],
    statsExpanded: false,
  }),
  computed: {
    ...mapState('reservations', ['areas', 'date', 'slots', 'slotsLoading']),
    ...mapGetters('reservations', ['checkedIns']),
    filteredCheckedins() {
      if (!this.search) return this.checkedIns
      return this.checkedIns.filter(r => {
        let name = [r.client_number, r.first_name, r.last_name, r.email].join(' ')
        return name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    },
    historyDay() {
      return this.moment(this.date).isBefore(this.moment(), 'day')
    },
    checkedInsByArea() {
      return this.areas.map(area => {
        return {
          area: area,
          count: this.checkedIns.filter(r => r.reservation_area_id == area.id).length,
        }
      })
    },
  },
  watch: {
    checkedIns() {
      this.search = ''
    },
    date() {
      this.statsExpanded = false
    },
  },
}
</script>
