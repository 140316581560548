<template>
  <v-dialog v-model="dialog" @keydown.esc="close" max-width="400px" persistent>
    <v-form ref="form" lazy-validation @submit.prevent="submit">
      <v-card>
        <v-card-title class="headline">Edit {{ slots.length }} timeslots</v-card-title>
        <v-card-text class="pb-0">
          <v-row no-gutters>
            <v-col cols="12" class="text-no-wrap">
              <v-icon class="mr-1">tl-account-group</v-icon>
              Spots available in this slot:
              <span class="d-inline-block px-2">
                <v-text-field
                  v-model="commonProps.spots"
                  type="number"
                  style="max-width: 75px;"
                  filled
                  single-line
                  dense
                  :rules="[rules.minVal(1), rules.maxVal(10000)]"
                />
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field v-model="commonProps.details" filled label="Slot details" hint="Optional" dense />
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" v-if="reservationSettings.clientNumber">
              <v-checkbox
                v-model="commonProps.require_client_number"
                color="primary"
                class="mt-0"
                :indeterminate="commonProps.require_client_number == null"
              >
                <template v-slot:label>
                  <div>
                    Require client number
                    <div v-if="commonProps.require_client_number == null" class="caption info--text">
                      Slots have different values
                    </div>
                  </div>
                </template>
              </v-checkbox>
            </v-col>

            <v-col cols="12">
              <v-checkbox
                :value="commonProps.password !== null"
                color="primary"
                class="mt-0"
                :indeterminate="commonProps.password == ''"
                @change="commonProps.password = commonProps.password === null ? '' : null"
              >
                <template v-slot:label>
                  <div>
                    Require password
                    <div v-if="commonProps.password == ''" class="caption info--text">
                      Slots have different values
                    </div>
                  </div>
                </template>
              </v-checkbox>

              <v-text-field
                v-if="commonProps.password !== null"
                v-model="commonProps.password"
                label="Password"
                :type="showPwd ? 'text' : 'password'"
                :append-icon="showPwd ? 'tl-visibility-off' : 'tl-visibility'"
                prepend-icon="tl-lock"
                filled
                class="ml-3"
                @click:append="showPwd = !showPwd"
              />
            </v-col>

            <v-col cols="12">
              <v-checkbox
                v-model="commonProps.is_unrestricted"
                color="primary"
                class="mt-0"
                :indeterminate="commonProps.is_unrestricted == null"
              >
                <template v-slot:label>
                  <div>
                    Unrestricted
                    <div class="caption">
                      If bookings for this slot do not count in the max allowed number of bookings.
                    </div>
                    <div v-if="commonProps.is_unrestricted == null" class="caption info--text">
                      Slots have different values
                    </div>
                  </div>
                </template>
              </v-checkbox>
            </v-col>

            <v-col cols="12">
              <v-checkbox
                v-model="commonProps.live"
                color="primary"
                class="mt-0"
                :indeterminate="commonProps.live == null"
              >
                <template v-slot:label>
                  <div>
                    Live (visible to customers)
                    <div v-if="commonProps.live == null" class="caption info--text">Slots have different values</div>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="close">{{ $t('generic.cancel') }}</v-btn>
          <v-btn text type="submit" color="primary" :loading="loading" :disabled="loading">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from '@/services/axios'
import errorService from '@/services/error-service'
import { mapState } from 'vuex'
import * as rules from '@/services/input-rules'

export default {
  data: () => ({
    dialog: false,
    slots: [],
    commonProps: {},
    commonPropsBase: {
      spots: null,
      details: null,
      require_client_number: null,
      password: '',
      is_unrestricted: null,
      live: null,
    },
    loading: false,
    rules,
    showPwd: false,
  }),
  computed: {
    ...mapState(['gym']),
    reservationSettings() {
      let settings = this.gym.revervation_settings_json && JSON.parse(this.gym.revervation_settings_json)
      return settings || {}
    },
  },
  methods: {
    open(slots) {
      this.slots = [...slots]
      this.initCommonProps()
      this.dialog = true
      return new Promise(resolve => (this.resolve = resolve))
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.slots = []
        this.commonProps = {}
      }, 300)
    },
    initCommonProps() {
      this.commonProps = Object.assign({}, this.commonPropsBase)
      Object.keys(this.commonProps).forEach(prop => {
        if (this.slots.every(slot => slot[prop] == this.slots[0][prop])) {
          this.commonProps[prop] = this.slots[0][prop]
        }
      })
    },
    submit() {
      if (!this.$refs.form.validate()) return
      this.loading = true
      // Filter and copy the commonProps that are not equal to the base:
      let slot_params = Object.keys(this.commonProps)
        .filter(prop => this.commonProps[prop] != this.commonPropsBase[prop])
        .reduce((toSave, prop) => Object.assign(toSave, { [prop]: this.commonProps[prop] }), {})

      axios
        .put(`gyms/${this.gym.id}/slots/update_many`, { ids: this.slots.map(s => s.id), slot: slot_params })
        .then(() => {
          this.resolve(true)
          this.close()
        })
        .catch(errorService.toast)
        .finally(() => (this.loading = false))
    },
  },
}
</script>
