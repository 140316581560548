<template>
  <v-form ref="form" lazy-validation @submit.prevent.stop="submit">
    <v-list-item>
      <v-row>
        <v-col cols="8">
          <v-text-field
            v-model="area.name"
            label="Area name"
            placeholder="Bouldering"
            :rules="[rules.required]"
            hide-details
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="area.capacity"
            label="Max. capacity"
            placeholder="50"
            type="number"
            :rules="[rules.required, rules.minVal(0)]"
            :hint="area.capacity == 0 ? 'No limit' : ''"
            :persistent-hint="area.capacity == 0"
            :hide-details="area.capacity != 0"
          />
        </v-col>
      </v-row>
      <v-list-item-action>
        <v-btn text type="submit" :loading="loading">
          {{ area.id ? 'save' : 'add' }}
        </v-btn>
      </v-list-item-action>
      <v-list-item-action>
        <v-btn v-if="area.id" icon @click.stop="removeArea" :loading="removing">
          <v-icon>tl-delete</v-icon>
        </v-btn>
        <v-btn v-else icon disabled></v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
import axios from '@/services/axios'
import errorService from '@/services/error-service'
import * as rules from '@/services/input-rules'

export default {
  props: {
    area: { type: Object, default: () => ({ name: '', capacity: null }) },
  },
  data: () => ({
    loading: false,
    removing: false,
    rules,
  }),
  computed: {
    ...mapState(['gym']),
  },
  methods: {
    submit() {
      if (!this.$refs.form.validate()) return
      this.loading = true
      let request = this.area.id ? this.submitUpdate() : this.submitCreate()
      request
        .then(() => {
          this.$emit('refresh')
          this.$refs.form.reset()
        })
        .catch(errorService.toast)
        .finally(() => (this.loading = false))
    },
    submitCreate() {
      let newArea = { ...this.area, gym_id: this.gym.id }
      return axios.post(`gyms/${this.gym.id}/reservation_areas`, { ['reservation_area']: newArea }).then(() => {
        this.area.name = ''
        this.area.capacity = null
      })
    },
    submitUpdate() {
      return axios.put(`gyms/${this.gym.id}/reservation_areas/${this.area.id}`, this.area)
    },
    async removeArea() {
      if (!this.area.id) return

      let confirmed = await this.$store.dispatch('dialog/confirm', {
        maxWidth: 350,
        title: 'Remove area?',
        text: 'Are you sure? This cannot be undone.',
        ok: 'Remove',
      })
      if (!confirmed) return

      this.removing = true
      return axios
        .delete(`gyms/${this.gym.id}/reservation_areas/${this.area.id}`)
        .then(() => this.$emit('refresh'))
        .catch(errorService.toast)
        .finally(() => (this.removing = false))
    },
  },
}
</script>
