<template>
  <v-container>
    <v-card class="mx-auto" max-width="500">
      <v-card-title class="d-flex flex-column align-start">
        <div>Cancelled reservations</div>
        <div class="grey--text subtitle-1">Listing the number of cancellations per account</div>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-menu
              v-model="dateStartPickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateStart"
                  label="From date"
                  prepend-icon="tl-event"
                  style="max-width: 150px;"
                  filled
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="dateStart"
                :locale="$i18n.locale"
                :first-day-of-week="moment.localeData()._week.dow"
                :landscape="$vuetify.breakpoint.smAndUp"
                color="primary"
                @input="dateStartPickerMenu = false"
              />
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-menu
              v-model="dateEndPickerMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="dateEnd"
                  label="To date"
                  prepend-icon="tl-event"
                  style="max-width: 150px;"
                  filled
                  readonly
                  v-on="on"
                />
              </template>
              <v-date-picker
                v-model="dateEnd"
                :locale="$i18n.locale"
                :first-day-of-week="moment.localeData()._week.dow"
                :landscape="$vuetify.breakpoint.smAndUp"
                color="primary"
                @input="dateEndPickerMenu = false"
              />
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />
      <v-data-table
        :headers="headers"
        :items="cancellations"
        :options.sync="paginationOptionsTable"
        :footer-props="{ itemsPerPageOptions: paginationItemsPerPageOptions }"
        :server-items-length="paginationItemsTotal"
        :loading="loading"
        style="user-select: text"
      >
        <template v-slot:item.cancellations="{ item }">
          <span style="user-select: none">{{ item.cancellations }}</span>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/services/axios'
import errorService from '@/services/error-service'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import paginationMixin from '@/components/shared/pagination/pagination-mixin'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  mixins: [toolbarMixin, paginationMixin],
  data: () => ({
    tlToolbarTitle: 'Cancellations',
    cancellations: [],
    loading: false,
    paginationItemsPerPage: 50,
    dateStartPickerMenu: false,
    dateEndPickerMenu: false,
    dateStart: moment()
      .subtract(1, 'month')
      .format('YYYY-MM-DD'),
    dateEnd: moment().format('YYYY-MM-DD'),
    headers: [
      { value: 'email', text: 'Email', align: 'start', sortable: false },
      { value: 'cancellations', text: 'Cancellations', align: 'end', sortable: false },
    ],
  }),
  computed: {
    ...mapState(['user', 'gym']),
    tlToolbarBtnLeft() {
      return {
        icon: 'tl-arrow-back',
        action: () => {
          this.$router.pushSmart({ name: 'admin.reservations' }) // eslint-disable-line
        },
      }
    },
    fetchParams() {
      return {
        ...this.paginationFetchParams,
        date_start: moment(this.dateStart).toISOString(),
        date_end: moment(this.dateEnd).toISOString(),
      }
    },
  },
  created() {
    this.fetchCancellations()
  },
  watch: {
    fetchParams() {
      this.fetchCancellations()
    },
  },
  methods: {
    fetchCancellations() {
      this.loading = true
      let paramsStr = Object.entries(this.fetchParams)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
      axios
        .get(`/gyms/${this.gym.id}/reservations/cancellations?${paramsStr}`)
        .then(({ data }) => {
          this.paginationItemsTotal = data.total
          this.cancellations = Object.keys(data.data).map(key => {
            return { email: key, cancellations: data.data[key] }
          })
        })
        .catch(errorService.toast)
        .finally(() => (this.loading = false))
    },
    remove(email) {
      // console.log('remove. Email: ', email)
      return email
    },
  },
}
</script>
