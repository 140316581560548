export default {
  data: () => ({
    updateInterval: null,
  }),
  created() {
    this.updateInterval = setInterval(
      function() {
        this.updateIntervalFunction()
      }.bind(this),
      10000
    )
    this.updateIntervalFunction()
  },
  beforeDestroy() {
    clearInterval(this.updateInterval)
  },
}
