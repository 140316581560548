<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" md="8" lg="7" xl="4">
        <v-card :loading="loading">
          <v-toolbar :color="differentDay ? 'warning' : ''" :dark="differentDay">
            <v-menu
              v-model="datePickerDialog"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon>tl-calendar</v-icon>
                </v-btn>
              </template>
              <v-date-picker
                :value="date"
                :locale="$i18n.locale"
                :first-day-of-week="moment.localeData()._week.dow"
                :landscape="$vuetify.breakpoint.smAndUp"
                :picker-date.sync="monthStatsPickerDate"
                :events="monthStatsEvents"
                color="primary"
                @change="onDateChange($event)"
              />
            </v-menu>

            <v-btn icon @click="datePrev">
              <v-icon>tl-chevron-left</v-icon>
            </v-btn>
            <v-toolbar-title @click="datePickerDialog = !datePickerDialog">
              {{ moment(date).format('dddd, MMMM Do') }}
            </v-toolbar-title>
            <v-btn icon @click="dateNext">
              <v-icon>tl-chevron-right</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn icon @click="$store.dispatch('reservations/fetchSlots')" :loading="slotsLoading">
              <v-icon>tl-autorenew</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <div v-if="!slots.length && !loading" class="d-flex flex-column my-12">
              <div class="title text-center mb-4">No time slots available</div>
              <v-btn color="primary" class="mx-auto" :to="{ name: 'admin.reservations.slots' }">Manage slots</v-btn>
            </div>
            <template v-else>
              <v-row justify="space-between" align="center">
                <v-col class="py-0">
                  <v-text-field
                    v-model="search"
                    placeholder="Search reservations"
                    prepend-icon="tl-search"
                    hide-details
                    clearable
                  />
                </v-col>
                <v-col class="py-0">
                  <v-select
                    v-if="areas.length > 1"
                    v-model="areaIds"
                    :items="areas"
                    multiple
                    label="Filter areas"
                    item-text="name"
                    item-value="id"
                    prepend-icon="tl-filter"
                    hide-details
                    clearable
                  />
                </v-col>
              </v-row>
            </template>
          </v-card-text>

          <v-expansion-panels v-model="expanded" multiple inset hover class="elevation-0">
            <v-col cols="12" class="pa-0" v-if="slotsPassed.length">
              <v-row
                no-gutters
                align="center"
                justify="space-between"
                class="px-4 py-2"
                v-ripple
                @click="showSlotsPassed = !showSlotsPassed"
              >
                <div class="body-2 grey--text text--darken-1">Passed time slots</div>
                <v-btn icon>
                  <v-icon>tl-chevron-{{ showSlotsPassed ? 'up' : 'down' }}</v-icon>
                </v-btn>
              </v-row>
              <v-divider />
            </v-col>

            <template v-if="showSlotsPassed">
              <tl-reservations-admin-slot
                v-for="slot in slotsPassed"
                :key="slot.id"
                :time-slot="slot"
                :filtered-reservations="getFilteredReservations(slot)"
                @book="addBooking(slot)"
                @collapse="expanded = []"
              />
            </template>

            <v-col cols="12" class="pa-0" v-if="slotsActive.length">
              <div class="pa-4 body-2 grey--text text--darken-1">Active time slots</div>
              <v-divider />
            </v-col>
            <tl-reservations-admin-slot
              v-for="slot in slotsActive"
              :key="slot.id"
              :time-slot="slot"
              :filtered-reservations="getFilteredReservations(slot)"
              @book="addBooking(slot)"
              @collapse="expanded = []"
            />

            <v-col cols="12" class="pa-0" v-if="slotsFuture.length">
              <div class="pa-4 body-2 grey--text text--darken-1">Future time slots</div>
              <v-divider />
            </v-col>
            <tl-reservations-admin-slot
              v-for="slot in slotsFuture"
              :key="slot.id"
              :time-slot="slot"
              :filtered-reservations="getFilteredReservations(slot)"
              @book="addBooking(slot)"
              @collapse="expanded = []"
            />
          </v-expansion-panels>
          <tl-reservations-reserve-dialog ref="dialogReserve" />
        </v-card>
      </v-col>
      <v-col cols="12" md="4" xl="3">
        <tl-reservations-admin-checkedin />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import tlReservationsMonthStatsMixin from './tl-reservations-month-stats.mixin'
import tlReservationsReserveDialog from '@/components/gym/reservations/tl-reservations-reserve-dialog'
import tlReservationsAdminCheckedin from './tl-reservations-admin-checkedin'
import tlReservationsAdminSlot from './tl-reservations-admin-slot'
import reservationsStore from './reservations.store'

export default {
  mixins: [toolbarMixin, tlReservationsMonthStatsMixin],
  components: {
    tlReservationsReserveDialog,
    tlReservationsAdminCheckedin,
    tlReservationsAdminSlot,
  },
  data: () => ({
    tlToolbarTitle: 'Manage reservations',
    datePickerDialog: false,
    slotGroupsUpdateInterval: null,
    slotRefetchInterval: null,
    slotsPassed: [],
    slotsActive: [],
    slotsFuture: [],
    expanded: [],
    search: '',
    areaIds: [],
    showSlotsPassed: false,
  }),
  computed: {
    ...mapState('reservations', ['areas', 'area', 'areasLoading', 'date', 'slots', 'slotsLoading']),
    loading() {
      return this.areasLoading || this.slotsLoading
    },
    tlToolbarButtons() {
      return [
        {
          icon: 'tl-edit',
          text: 'Edit slots',
          action: () => {
            this.$router.pushSmart({ name: 'admin.reservations.slots' }) // eslint-disable-line
          },
        },
        {
          text: 'No-shows',
          action: () => {
            this.$router.pushSmart({ name: 'admin.reservations.noShows' }) // eslint-disable-line
          },
        },
        {
          text: 'Cancellations',
          action: () => {
            this.$router.pushSmart({ name: 'admin.reservations.cancellations' }) // eslint-disable-line
          },
        },
        {
          text: 'Data export',
          action: () => {
            this.$router.pushSmart({ name: 'admin.reservations.table' }) // eslint-disable-line
          },
        },
        {
          text: 'Settings',
          action: () => {
            this.$router.pushSmart({ name: 'admin.reservations.settings' }) // eslint-disable-line
          },
        },
      ]
    },
    slotsFiltered() {
      let filtered = this.slots
      if (this.search) filtered = filtered.filter(slot => this.getFilteredReservations(slot).length)
      if (this.areaIds.length)
        filtered = filtered.filter(slot => slot.reservation_area_id && this.areaIds.includes(slot.reservation_area_id))
      return filtered
    },
    differentDay() {
      return !this.moment(this.date).isSame(this.moment(), 'day')
    },
  },
  beforeCreate() {
    this.$store.registerModuleOnce('reservations', reservationsStore)
  },
  created() {
    this.$store.dispatch('reservations/fetchAreas')
    this.$store.commit('reservations/setArea')
    this.$store.commit('reservations/setDate') // Resets the date to today

    this.slotGroupsUpdateInterval = setInterval(
      function() {
        this.updateSlotGroups()
      }.bind(this),
      5000
    )
    this.updateSlotGroups()

    // Periodically refresh to fetch the latest bookings:
    this.slotRefetchInterval = setInterval(
      function() {
        this.$store.dispatch('reservations/fetchSlots')
      }.bind(this),
      181000
    )
  },
  beforeDestroy() {
    clearInterval(this.slotGroupsUpdateInterval)
    clearInterval(this.slotRefetchInterval)
  },
  watch: {
    date: {
      immediate: true,
      handler() {
        this.showSlotsPassed = this.moment(this.date).isBefore(this.moment(), 'd')
        this.$store.dispatch('reservations/fetchSlots')
      },
    },
    slotsFiltered() {
      this.updateSlotGroups()
    },
  },
  methods: {
    datePrev() {
      let newDate = this.moment(this.date)
        .subtract(1, 'day')
        .format('YYYY-MM-DD')
      this.$store.commit('reservations/setDate', newDate)
    },
    dateNext() {
      let newDate = this.moment(this.date)
        .add(1, 'day')
        .format('YYYY-MM-DD')
      this.$store.commit('reservations/setDate', newDate)
    },
    onDateChange(newDate) {
      this.$store.commit('reservations/setDate', newDate)
      this.datePickerDialog = false
    },
    updateSlotGroups() {
      this.slotsPassed = this.slotsFiltered.filter(slot => this.moment(slot.end_at).isBefore())
      this.slotsActive = this.slotsFiltered.filter(
        slot => this.moment(slot.start_at).isBefore() && this.moment(slot.end_at).isAfter()
      )
      this.slotsFuture = this.slotsFiltered.filter(slot => this.moment(slot.start_at).isAfter())
    },
    getFilteredReservations(slot) {
      if (!this.search) return slot.reservations
      return slot.reservations.filter(r => {
        let name = [`#${r.booking_number}`, r.client_number, r.first_name, r.last_name, r.email].join(' ')
        return name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
      })
    },
    async addBooking(slot) {
      let confirm = await this.$refs.dialogReserve.open(slot, true)
      if (!confirm) return
      this.$store.dispatch('reservations/fetchSlots')
    },
  },
}
</script>
