<template>
  <div class="tl-reservations-graph" ref="container" v-resize="updateWidth" :style="{ height: `${height}px` }">
    <svg ref="svg" :width="width" :height="height" @mousemove="onMouseMove" @mouseleave="onMouseLeave">
      <g class="chart" ref="chart" :transform="`translate(${margin.left},${margin.top})`">
        <path class="line" :d="lineSpots(steps)" stroke="#ff0094" />
        <path class="line" :d="lineReservations(steps)" stroke="grey" />
        <path class="line" :d="lineCheckins(steps)" stroke="black" />
        <g
          v-axis:x="{ scale: scaleX, ticks: d3.utcHour.every(2), tickSize: 10 }"
          :transform="`translate(0,${heightChart})`"
        ></g>
        <g
          v-axis:x="{ scale: scaleX, ticks: d3.utcMinute.every(15), ticksFormat: '' }"
          :transform="`translate(0,${heightChart})`"
        ></g>
        <g class="axis axis__y" v-axis:y="{ scale: scaleY }"></g>
        <g class="grid" v-axis:y="{ scale: scaleY, tickFormat: () => '', tickSize: -widthChart }" />
      </g>
    </svg>
    <div v-if="stepHover" class="tl-tooltip text-no-wrap" :style="{ left: tooltipX }">
      <div>{{ moment(stepHover.time).format('LT') }}</div>
      <div>Spots: {{ stepHover.spots }}</div>
      <div>Reservations: {{ stepHover.reservations }}</div>
      <div>Checked in: {{ stepHover.checkins }}</div>
    </div>
    <div v-if="stepHover" class="tl-tooltip__line" :style="{ left: tooltipX, height: `${heightChart}px` }" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { round } from '@/services/utils'
import { parseDateSafe } from '@/services/parsers'
import d3 from '@/services/d3-stripped'

export default {
  props: {
    areaId: { type: Number, default: null },
  },
  data: () => ({
    width: 0,
    margin: {
      top: 5,
      right: 5,
      bottom: 20,
      left: 20,
    },
    stepHover: null,
    debounceMousemove: null,
    d3,
  }),
  computed: {
    ...mapState('reservations', ['areas', 'slots', 'slotsLoading']),
    ...mapGetters('reservations', ['reservations']),
    height() {
      return Math.max(round((this.width * 2) / 5), 200)
    },
    heightChart() {
      return this.height - this.margin.top - this.margin.bottom
    },
    widthChart() {
      return this.width - this.margin.left - this.margin.right
    },
    domain() {
      const x = !this.steps.length // eslint-disable-line
        ? [new Date(), new Date()]
        : d3.extent(this.steps, step => step.time)
      const y = [0, d3.max(this.steps, step => d3.max([step.spots, step.reservations, step.checkins])) + 5]
      return { x, y }
    },
    scaleX() {
      return d3
        .scaleTime()
        .domain(this.domain.x)
        .range([0, this.widthChart])
    },
    scaleY() {
      return d3
        .scaleLinear()
        .domain(this.domain.y)
        .range([this.heightChart, 0])
    },
    lineSpots() {
      return d3
        .line()
        .curve(d3.curveMonotoneX)
        .x(d => this.scaleX(d.time))
        .y(d => this.scaleY(d.spots))
    },
    lineReservations() {
      return d3
        .line()
        .curve(d3.curveMonotoneX)
        .x(d => this.scaleX(d.time))
        .y(d => this.scaleY(d.reservations))
    },
    lineCheckins() {
      return d3
        .line()
        .curve(d3.curveMonotoneX)
        .x(d => this.scaleX(d.time))
        .y(d => this.scaleY(d.checkins))
    },
    areaSlots() {
      return this.slots.filter(slot => slot.reservation_area_id == this.areaId)
    },
    areaReservations() {
      return this.reservations.filter(r => r.reservation_area_id == this.areaId)
    },
    startAtMin() {
      return Math.min(...this.areaSlots.map(slot => new Date(slot.start_at)))
    },
    endAtMax() {
      return Math.max(...this.areaSlots.map(slot => new Date(slot.end_at)))
    },
    steps() {
      let start = this.startAtMin
      let end = this.endAtMax
      let time = start
      let i = 0

      let steps = []
      while (this.moment(time).isBefore(end)) {
        let slotsOverlapping = this.areaSlots.filter(slot => {
          return this.moment(slot.start_at).isSameOrBefore(time) && this.moment(slot.end_at).isAfter(time)
        })
        let reservationsOverlapping = this.areaReservations.filter(reservation => {
          return (
            !reservation.cancelled_at &&
            this.moment(reservation.slot_start_at).isSameOrBefore(time) &&
            this.moment(reservation.slot_end_at).isAfter(time)
          )
        })
        let checkedInsOverlapping = this.areaReservations.filter(reservation => {
          return (
            !reservation.cancelled_at &&
            reservation.in_at &&
            this.moment(reservation.in_at).isSameOrBefore(time) &&
            (!reservation.out_at || this.moment(reservation.out_at).isAfter(time))
          )
        })

        let spots = slotsOverlapping.reduce((sum, slot) => sum + slot.spots, 0)
        let reservations = reservationsOverlapping.length
        let checkins = checkedInsOverlapping.length
        steps.push({ time: parseDateSafe(time), spots, reservations, checkins })

        // Prevent infinity-loop:
        if (i++ >= 1000) break
        time = this.moment(time).add(10, 'm').toISOString() // eslint-disable-line
      }
      return steps
    },
    tooltipX() {
      return this.scaleX(this.stepHover.time) + this.margin.left + 'px'
    },
  },
  directives: {
    axis(el, binding) {
      const v = binding.value
      const axis = binding.arg
      const axisMethod = { x: 'axisBottom', y: 'axisLeft' }[axis]
      let axisGenerator = d3[axisMethod](v.scale)
      if (v.ticks) axisGenerator.ticks(v.ticks, v.ticksFormat)
      if (v.tickValues) axisGenerator.tickValues(v.tickValues)
      if (v.tickFormat) axisGenerator.tickFormat(v.tickFormat)
      if (v.tickSize) axisGenerator.tickSize(v.tickSize)
      d3.select(el).call(axisGenerator)
    },
  },
  mounted() {
    this.updateWidth()
  },
  methods: {
    updateWidth() {
      this.$nextTick(() => {
        this.width = this.$refs.container.clientWidth
      })
    },
    onMouseMove(e) {
      if (this.debounceMousemove) return
      this.handleMouseMove(e)
      this.debounceMousemove = true
      setTimeout(() => {
        this.debounceMousemove = false
      }, 50)
    },
    handleMouseMove(e) {
      let chart = this.$refs.chart
      let x = e.clientX - this.margin.left - chart.getBoundingClientRect().left
      this.stepHover = x > 0 ? this.steps.find(step => this.scaleX(step.time) >= x) : null
    },
    onMouseLeave() {
      this.stepHover = null
    },
  },
}
</script>

<style lang="sass">
.tl-reservations-graph
  position: relative
  width: 100%
  svg
    fill: rgba(255, 255, 255, 0.7)
  .line
    fill: none
    stroke-width: 3px
  .dot
    fill: #ff0094
  .grid
    .tick
      stroke: var(--v-grey-darken1)
      stroke-opacity: 0.3
      shape-rendering: crispEdges
    path
      stroke-width: 0
  .tl-tooltip
    position: absolute
    transform: translate(-50%, -100%)
    top: 0
    text-align: center
    padding: 4px
    font: 16px sans-serif
    color: white
    font-weight: 500
    background: black
    border: 0px
    border-radius: 4px
    pointer-events: none
    &__line
      position: absolute
      top: 5px
      width: 0
      border-left: 1px dotted grey
</style>
