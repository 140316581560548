<template>
  <v-container fluid>
    <v-card class="mx-auto">
      <v-toolbar flat>
        <v-toolbar-title class="title">Reservations table</v-toolbar-title>
        <v-spacer />
        <tl-table-customize-headers
          :headers="headers"
          :headers-enabled.sync="headersEnabled"
          :headers-always-values="['actions']"
          storage-key="tlReservationsExportTableHeaders"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on"><v-icon>tl-settings</v-icon></v-btn>
          </template>
        </tl-table-customize-headers>
      </v-toolbar>

      <v-card-text class="pt-0">
        <v-select
          v-if="areas.length > 1"
          v-model="reservationAreaIds"
          :items="areas"
          label="Select your area"
          item-text="name"
          item-value="id"
          multiple
        />

        <p>Select all slots <b>overlapping</b> the following period:</p>
        <v-row no-gutters>
          <v-col cols="12" md="6">
            <v-row no-gutters class="align-center">
              <v-col cols="2">Start</v-col>
              <v-col class="d-flex flex-no-wrap">
                <tl-date-picker v-model="startAt" typeable dense single-line />
                <span class="pl-4" />
                <tl-time-picker v-model="startAt" typeable dense single-line />
              </v-col>
            </v-row>
            <v-row no-gutters class="align-center">
              <v-col cols="2">End</v-col>
              <v-col class="d-flex flex-no-wrap">
                <tl-date-picker v-model="endAt" typeable dense single-line />
                <span class="pl-4" />
                <tl-time-picker v-model="endAt" typeable dense single-line class="ml-2" />
              </v-col>
            </v-row>
          </v-col>

          <v-divider vertical v-if="$vuetify.breakpoint.mdAndUp" />

          <v-col cols="12" md="6" class="pl-md-4" style="margin-left: -1px">
            <v-row no-gutters>
              <v-col cols="12">
                <v-switch v-model="show_cancelled" label="Show cancelled" color="primary" class="mt-0" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-switch v-model="show_no_shows" label="Include no-shows" color="primary" class="mt-0" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-switch v-model="hide_non_checkedins" label="Show checked-in only" color="primary" class="mt-0" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />
      <v-data-table
        :headers="headersEnabled"
        :items="reservations"
        :options.sync="paginationOptionsTable"
        :footer-props="{ itemsPerPageOptions: paginationItemsPerPageOptions }"
        :server-items-length="paginationItemsTotal"
        :loading="loading"
        style="user-select: text"
      >
        <template v-slot:item.in_at="{ item }">
          <span v-if="item.in_at">{{ item.in_at | formatDate('lll') }}</span>
        </template>
        <template v-slot:item.out_at="{ item }">
          <span v-if="item.out_at">{{ item.out_at | formatDate('lll') }}</span>
        </template>
        <template v-slot:item.slot_start_at="{ item }">
          <span v-if="item.slot_start_at">{{ item.slot_start_at | formatDate('lll') }}</span>
        </template>
        <template v-slot:item.slot_end_at="{ item }">
          <span v-if="item.slot_end_at">{{ item.slot_end_at | formatDate('lll') }}</span>
        </template>

        <template v-slot:item.cancelled_at="{ item }">
          <v-icon v-if="item.cancelled_at">tl-check</v-icon>
        </template>

        <template v-slot:item.no_show="{ item }">
          <v-icon v-if="item.no_show">tl-check</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import axios from '@/services/axios'
import errorService from '@/services/error-service'
import toolbarMixin from '@/components/layout/toolbar/toolbar.mixin'
import paginationMixin from '@/components/shared/pagination/pagination-mixin'
import tlDatePicker from '@/components/shared/moment/tl-date-picker'
import tlTimePicker from '@/components/shared/moment/tl-time-picker'
import tlTableCustomizeHeaders from '@/components/shared/tl-table-customize-headers'
import { mapState } from 'vuex'
import reservationsStore from './reservations.store'
import moment from 'moment'

export default {
  mixins: [toolbarMixin, paginationMixin],
  components: {
    tlDatePicker,
    tlTimePicker,
    tlTableCustomizeHeaders,
  },
  data: () => ({
    tlToolbarTitle: 'Export reservations',
    reservations: [],
    loading: false,
    paginationItemsPerPage: 50,
    paginationOrderBy: ['slot_start_at'],
    timePickerMenuFrom: false,
    datePickerMenuFrom: false,
    timePickerMenuTo: false,
    datePickerMenuTo: false,
    reservationAreaIds: [],
    startAt: moment()
      .subtract(2, 'hour')
      .toISOString(),
    endAt: moment().toISOString(),
    show_no_shows: false,
    show_cancelled: false,
    hide_non_checkedins: false,
    headers: [
      { value: 'booking_number', text: 'Booking #', align: 'start', sortable: true },
      { value: 'first_name', text: 'First name', align: 'start', sortable: true },
      { value: 'last_name', text: 'Last name', align: 'start', sortable: true },
      { value: 'email', text: 'Email', align: 'start', sortable: true },
      { value: 'slot_start_at', text: 'Slot start', align: 'start', sortable: true },
      { value: 'slot_end_at', text: 'Slot end', align: 'start', sortable: true },
      { value: 'in_at', text: 'Checked in', align: 'start', sortable: true },
      { value: 'out_at', text: 'Checked out', align: 'start', sortable: true },
      { value: 'cancelled_at', text: 'Cancelled', align: 'start', sortable: true },
      { value: 'no_show', text: 'No-show', align: 'start', sortable: true },
    ],
    headersEnabled: [],
  }),
  computed: {
    ...mapState(['user', 'gym']),
    ...mapState('reservations', ['areas', 'areasLoading']),
    tlToolbarBtnLeft() {
      return {
        icon: 'tl-arrow-back',
        action: () => {
          this.$router.pushSmart({ name: 'admin.reservations' }) // eslint-disable-line
        },
      }
    },
    fetchParams() {
      return {
        ...this.paginationFetchParams,
        start_at: moment(this.startAt).toISOString(),
        end_at: moment(this.endAt).toISOString(),
        show_no_shows: this.show_no_shows,
        show_cancelled: this.show_cancelled,
        hide_non_checkedins: this.hide_non_checkedins,
        reservation_area_ids: this.reservationAreaIds,
      }
    },
  },
  beforeCreate() {
    this.$store.registerModuleOnce('reservations', reservationsStore)
  },
  created() {
    this.fetchReservations()
    this.$store.dispatch('reservations/fetchAreas')
  },
  watch: {
    fetchParams() {
      this.fetchReservations()
    },
  },
  methods: {
    fetchReservations() {
      this.loading = true
      let paramsStr = Object.entries(this.fetchParams)
        .filter(([, val]) => val)
        .map(([key, val]) => `${key}=${val}`)
        .join('&')
      axios
        .get(`/gyms/${this.gym.id}/reservations/table?${paramsStr}`)
        .then(({ data }) => {
          this.paginationItemsTotal = data.total
          if (this.paginationPage > Math.ceil(this.paginationItemsTotal / this.paginationItemsPerPage)) {
            this.paginationPage = 1
          }
          this.reservations = data.data
        })
        .catch(errorService.toast)
        .finally(() => (this.loading = false))
    },
  },
}
</script>
