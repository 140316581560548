<template>
  <v-dialog v-model="dialog" @keydown.esc="close" max-width="400px" persistent>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submit">
      <v-card>
        <v-card-title class="headline">{{ isNew ? 'Add timeslot' : 'Edit timeslot' }}</v-card-title>
        <v-card-text class="pb-0">
          <v-row no-gutters>
            <v-col>
              <v-menu
                v-model="datePickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date"
                    label="Date"
                    prepend-icon="tl-event"
                    style="max-width: 150px;"
                    filled
                    single-line
                    dense
                    readonly
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  :locale="$i18n.locale"
                  :first-day-of-week="moment.localeData()._week.dow"
                  :landscape="$vuetify.breakpoint.smAndUp"
                  color="primary"
                  @input="datePickerMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                ref="timePickerMenu"
                v-model="timePickerMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="time"
                    label="Time"
                    prepend-icon="tl-access-time"
                    style="max-width: 108px;"
                    filled
                    single-line
                    dense
                    readonly
                    v-on="on"
                  />
                </template>
                <v-time-picker
                  v-if="timePickerMenu"
                  v-model="time"
                  full-width
                  format="24hr"
                  @click:minute="$refs.timePickerMenu.save(time)"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" class="text-no-wrap">
              <v-icon class="mr-1">tl-timer</v-icon>
              Climbing time:
              <span class="d-inline-block px-2">
                <v-text-field
                  v-model="slot.duration"
                  type="number"
                  style="max-width: 75px;"
                  filled
                  single-line
                  dense
                  :rules="[rules.required, rules.minVal(5), rules.maxVal(1439)]"
                />
              </span>
              minutes
            </v-col>
          </v-row>

          <v-row no-gutters v-if="reservationSettings.checkinDuration">
            <v-col cols="12" class="text-no-wrap">
              <v-icon class="mr-1">tl-account-arrow-right</v-icon>
              Checkin period: the first
              <span class="d-inline-block px-2">
                <v-text-field
                  v-model="slot.checkinDuration"
                  type="number"
                  style="max-width: 75px;"
                  filled
                  single-line
                  dense
                  :rules="[rules.required, rules.minVal(0), rules.maxVal(slot.duration)]"
                />
              </span>
              minutes
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" class="text-no-wrap">
              <v-icon class="mr-1">tl-account-group</v-icon>
              Spots available in this slot:
              <span class="d-inline-block px-2">
                <v-text-field
                  v-model="slot.spots"
                  type="number"
                  style="max-width: 75px;"
                  filled
                  single-line
                  dense
                  :rules="[rules.required, rules.minVal(1), rules.maxVal(10000)]"
                />
              </span>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field v-model="slot.details" filled label="Slot details" hint="Optional" dense />
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" v-if="reservationSettings.clientNumber">
              <v-switch
                v-model="slot.require_client_number"
                color="primary"
                label="Require client number"
                class="mt-0"
              />
            </v-col>
            <v-col cols="12">
              <v-switch
                v-model="slot.require_password"
                color="primary"
                label="Require password"
                class="mt-0"
                @change="slot.password = null"
              />
              <v-text-field
                v-if="slot.require_password || slot.password"
                v-model="slot.password"
                label="Password"
                :type="showPwd ? 'text' : 'password'"
                :rules="[rules.required]"
                :append-icon="showPwd ? 'tl-visibility-off' : 'tl-visibility'"
                prepend-icon="tl-lock"
                filled
                class="ml-3"
                @click:append="showPwd = !showPwd"
              />
            </v-col>
            <v-col cols="12">
              <v-switch v-model="slot.is_unrestricted" color="primary" label="Unrestricted" class="mt-0" hide-details />
              <div class="ml-12 mb-2 caption">
                If bookings for this slot do not count in the max allowed number of bookings.
              </div>
            </v-col>
            <v-col cols="12">
              <v-switch v-model="slot.live" color="primary" label="Live (visible to customers)" class="mt-0" />
            </v-col>
          </v-row>

          <template v-if="isNew">
            <v-divider />
            <v-row no-gutters>
              <v-col cols="12">
                <v-switch v-model="repeats" color="primary" label="Slot repeats" />
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-row v-show="repeats" no-gutters align="center">
                <v-col cols="12" class="text-no-wrap">
                  Repeat slot every
                  <span class="d-inline-block px-2">
                    <v-text-field
                      v-model="repeatsInterval"
                      type="number"
                      style="max-width: 75px;"
                      filled
                      single-line
                      dense
                      :rules="[rules.required, rules.minVal(5), rules.maxVal(1439)]"
                    />
                  </span>
                  minutes
                </v-col>
                <v-col cols="12" class="text-no-wrap">
                  Repeat slots until
                  <span class="d-inline-block px-2">
                    <v-menu
                      ref="repeatsTimePickerMenu"
                      v-model="repeatsTimePickerMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="repeatsEndTime"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="repeatsEndTime"
                          readonly
                          filled
                          single-line
                          dense
                          hide-details
                          style="max-width: 75px;"
                          v-on="on"
                        />
                      </template>
                      <v-time-picker
                        v-if="repeatsTimePickerMenu"
                        v-model="repeatsEndTime"
                        full-width
                        format="24hr"
                        :min="time"
                        @click:minute="$refs.repeatsTimePickerMenu.save(repeatsEndTime)"
                      />
                    </v-menu>
                  </span>
                </v-col>
                <v-col v-if="maxClients" cols="12" class="d-flex justify-space-between align-center flex-nowrap">
                  <v-switch
                    v-model="preserveMaxClients"
                    color="primary"
                    :label="`Retain max number of clients (${maxClients})`"
                  />
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon color="grey" v-on="on">tl-info</v-icon>
                    </template>
                    <span>
                      Skip slot if it would exceed the max allowed number of clients<br />
                      as set up in the reservation settings tab.<br />
                    </span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12">
                  <v-divider />
                </v-col>
              </v-row>
            </v-expand-transition>
          </template>
        </v-card-text>

        <template v-if="isNew && repeats">
          <v-list-item :two-line="newSlots.length >= maxSlots" @click="showSlots = !showSlots">
            <v-list-item-avatar v-if="newSlots.length >= maxSlots">
              <v-icon class="mr-1">tl-warning</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ newSlots.length }} slots will be generated</v-list-item-title>
              <v-list-item-subtitle v-if="newSlots.length >= maxSlots">
                Max {{ maxSlots }} slots can be created at once.
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>tl-chevron-{{ showSlots ? 'up' : 'down' }}</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-expand-transition>
            <v-simple-table dense v-show="showSlots">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center">Start</th>
                    <th class="text-center">End</th>
                    <th class="text-center">Spots</th>
                    <th class="text-center">Active spots<br />(Start - End)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(slut, index) in slots" :key="index" :class="{ 'grey--text': slut.id }">
                    <td class="text-center">{{ moment(slut.start_at).format('HH:mm') }}</td>
                    <td class="text-center">{{ moment(slut.end_at).format('HH:mm') }}</td>
                    <td class="text-center">{{ slut.spots }}</td>
                    <td class="text-center">{{ slut.spotsUsedStart }} - {{ slut.spotsUsedEnd }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-expand-transition>
        </template>

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="close">{{ $t('generic.cancel') }}</v-btn>
          <v-btn text type="submit" color="primary" :loading="loading" :disabled="loading || !valid">
            {{ isNew ? 'Add' : 'Save' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from '@/services/axios'
import errorService from '@/services/error-service'
import { mapState } from 'vuex'
import * as rules from '@/services/input-rules'

export default {
  data: () => ({
    dialog: false,
    slot: {},
    loading: false,
    rules,
    valid: true,
    datePickerMenu: false,
    timePickerMenu: false,
    repeatsTimePickerMenu: false,
    repeats: false,
    repeatsInterval: 30,
    repeatsEndTime: null,
    preserveMaxClients: true,
    maxSlots: 100,
    showSlots: false,
    showPwd: false,
  }),
  computed: {
    ...mapState(['gym']),
    ...mapState('reservations', ['area']),
    isNew() {
      return !this.slot.id
    },
    date: {
      get() {
        return this.moment(this.slot.start_at).format('YYYY-MM-DD')
      },
      set(newVal) {
        this.slot.start_at = this.updateDate(this.slot.start_at, newVal)
      },
    },
    time: {
      get() {
        return this.moment(this.slot.start_at).format('HH:mm')
      },
      set(newVal) {
        this.slot.start_at = this.updateTime(this.slot.start_at, newVal)
      },
    },
    endAt() {
      return this.moment(this.slot.start_at)
        .add(this.slot.duration, 'm')
        .toISOString()
    },
    checkinEndAt() {
      if (!this.slot.checkinDuration) return null
      return this.moment(this.slot.start_at)
        .add(this.slot.checkinDuration, 'm')
        .toISOString()
    },
    repeatsEndAt() {
      let end = this.moment(this.updateTime(this.slot.start_at, this.repeatsEndTime))
      let max = this.moment(this.slot.start_at).endOf('day')
      return (end.isAfter(max) ? max : end).toISOString()
    },
    maxClients() {
      return this.area && this.area.capacity
    },
    slots() {
      if (!this.repeats) return [this.getSlotFormatted()]
      let slots = [...this.$store.state.reservations.slots]
      if (this.repeatsInterval < 5 || this.slot.duration < 5) return slots // Prevent invinityloop
      let newSlotBase = { ...this.getSlotFormatted() }
      let newSlot = { ...newSlotBase }
      while (this.moment(newSlot.start_at).isBefore(this.repeatsEndAt)) {
        if (this.preserveMaxClients && this.maxClients) {
          // Calculate the maximum allowed number of spots we can add:
          // 1. Filter slots overlapping this slot:
          let slotsOverlapping = slots.filter(s => {
            return this.moment(s.start_at).isBefore(newSlot.end_at) && this.moment(s.end_at).isAfter(newSlot.start_at)
          })

          // 2. All the start- and end times of the overlapping slots are moments we should check:
          let checkPoints = []
          slotsOverlapping.forEach(slot => {
            checkPoints.push(slot.start_at)
            checkPoints.push(slot.end_at)
          })

          // 3. Get the highest number of available spots at any checkpoint:
          let spotsUsedMax = Math.max(
            ...checkPoints.map(checkPoint => {
              return slotsOverlapping
                .filter(
                  slot =>
                    this.moment(slot.start_at).isSameOrBefore(checkPoint) &&
                    this.moment(slot.end_at).isAfter(checkPoint)
                )
                .reduce((sum, slot) => sum + slot.spots, 0)
            })
          )

          // 4. Get the number of spots starting at the same time (we should prevent crowds):
          let spotsSameStart = slotsOverlapping
            .filter(s => this.moment(s.start_at).isSame(newSlot.start_at))
            .reduce((sum, slot) => sum + slot.spots, 0)

          // 5. Get the remaining number of spots we are allowed to add:
          let spotsLeftTotal = this.maxClients - spotsUsedMax // Restrict max spots allowed in the gym at the same time
          let spotsLeftStart = newSlotBase.spots - spotsSameStart // Restrict max spots starting at same time
          newSlot.spots = Math.min(spotsLeftStart, spotsLeftTotal)
        }

        // Add the slot if there are spots in it:
        if (newSlot.spots > 0) {
          slots.push(newSlot)
        }

        // Prevent infinity-loops and non-responsiveness:
        if (slots.length >= this.maxSlots) break

        // Prepare a new slot for the next iteration, shifted in time by the repeatsInterval:
        newSlot = { ...newSlot }
        newSlot.start_at = this.moment(newSlot.start_at).add(this.repeatsInterval, 'm').toISOString() // eslint-disable-line
        newSlot.end_at = this.moment(newSlot.end_at).add(this.repeatsInterval, 'm').toISOString() // eslint-disable-line
        if (newSlot.checkin_end_at) {
          newSlot.checkin_end_at = this.moment(newSlot.checkin_end_at).add(this.repeatsInterval, 'm').toISOString() // eslint-disable-line
        }
      }

      // Augment the resulting slots array with spots used at start and end:
      slots.sort((s1, s2) => new Date(s1.start_at) - new Date(s2.start_at))
      slots.forEach(slot => {
        slot.spotsUsedStart = slots
          .filter(s => {
            return this.moment(s.start_at).isSameOrBefore(slot.start_at) && this.moment(s.end_at).isAfter(slot.start_at)
          })
          .reduce((sum, s) => sum + s.spots, 0)
        slot.spotsUsedEnd = slots
          .filter(s => {
            return this.moment(s.start_at).isBefore(slot.end_at) && this.moment(s.end_at).isSameOrAfter(slot.end_at)
          })
          .reduce((sum, s) => sum + s.spots, 0)
      })
      return slots
    },
    newSlots() {
      return this.slots.filter(s => !s.id)
    },
    reservationSettings() {
      let settings = this.gym.revervation_settings_json && JSON.parse(this.gym.revervation_settings_json)
      return settings || {}
    },
  },
  watch: {
    repeats() {
      let end = this.moment(this.slot.start_at).add(2, 'h')
      let max = this.moment(this.slot.start_at).endOf('day')
      this.repeatsEndTime = (end.isAfter(max) ? max : end).format('HH:mm')
    },
  },
  methods: {
    parseSlot(slot) {
      let parsed = { ...slot }

      parsed.reservation_area_id = slot.reservation_area_id || this.area.id
      parsed.start_at = slot.start_at || this.updateDate(this.moment(), this.$store.state.reservations.date)

      parsed.duration = slot.end_at
        ? this.moment(slot.end_at).diff(parsed.start_at, 'm')
        : this.reservationSettings.slotDuration || 120
      delete parsed.end_at

      if (this.reservationSettings.checkinDuration) {
        parsed.checkinDuration = slot.checkin_end_at
          ? this.moment(slot.checkin_end_at).diff(parsed.start_at, 'm')
          : this.reservationSettings.checkinDuration
        delete parsed.checkin_end_at
      }

      parsed.spots = slot.spots || 10
      parsed.live = slot.id ? !!slot.live : true
      return parsed
    },
    getSlotFormatted() {
      let slot = { ...this.slot }
      slot.reservation_area_id = this.area.id
      slot.spots = parseInt(slot.spots)
      slot.end_at = this.endAt
      delete slot.duration
      slot.checkin_end_at = slot.checkinDuration ? this.checkinEndAt : null
      delete slot.checkinDuration
      return slot
    },
    open(slot) {
      this.slot = this.parseSlot(slot)
      this.dialog = true
      return new Promise(resolve => (this.resolve = resolve))
    },
    close() {
      this.dialog = false
      setTimeout(() => {
        this.slot = {}
        this.repeats = false
      }, 300)
    },
    submit() {
      if (!this.$refs.form.validate()) return
      this.loading = true
      let request = this.isNew ? this.submitCreate() : this.submitUpdate()
      request
        .then(() => {
          this.resolve(true)
          this.close()
        })
        .catch(errorService.toast)
        .finally(() => (this.loading = false))
    },
    submitCreate() {
      return axios.post(`gyms/${this.gym.id}/slots/create_many`, { slots: this.newSlots })
    },
    submitUpdate() {
      return axios.put(`gyms/${this.gym.id}/slots/${this.slot.id}`, this.getSlotFormatted())
    },
    updateDate(dateTime, date) {
      date = date || this.moment(dateTime).format('YYYY-MM-DD')
      let time = this.moment(dateTime).format('HH:mm')
      return this.moment(date + 'T' + time).toISOString()
    },
    updateTime(dateTime, time) {
      let date = this.moment(dateTime).format('YYYY-MM-DD')
      time = time || this.moment(dateTime).format('HH:mm')
      return this.moment(date + 'T' + time).toISOString()
    },
  },
}
</script>
